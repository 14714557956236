.icons {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1000; //https://ant.design/components/modal/ -> The z-index of the Modal number 1000 (na przykładzie VATUEKModalContent) ustawiam na taką liczbę aby nie nachodziło na modal

  .icon {
    cursor: pointer;
  }

  .icon.blocked {
    cursor: initial;
    opacity: 0.3;
  }
}

.icons__edit {
  display: flex;
  justify-content: space-between;
  width: 76px;
  top: 36px;
  right: 67px;
  z-index: 1000; //https://ant.design/components/modal/ -> The z-index of the Modal number 1000 (na przykładzie VATUEKModalContent) ustawiam na taką liczbę aby nie nachodziło na modal
}

@primary-color: #1DA57A;