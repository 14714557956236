.Main-layout {
  padding: 0;
  margin-left: 75px;
}

.Main-site-layout-background {
  background: #fff;
  margin: 0;
  // min-height: 280px;
}

.Main {
  height: 100vh;
}

.ant-notification.ant-notification-bottomRight .ant-notification-notice-message,
.ant-notification-notice-close {
  display: none;
}

.ant-notification-notice {
  padding: 4px 5px;
  line-height: 1.66667;
  border-radius: 4px;
}

@primary-color: #1DA57A;