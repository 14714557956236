.ClientPanel.details-visible {
    .table-details {
        padding:0px 50px;
        .closeButton {
            position: absolute;
            margin-top: 10px;
            margin-right: 25px;
            padding: 5px;
            border: none;
            right: 0px;
            left: auto;
            border: none;
            background: transparent;

            img {
                height: 10px;
                width: 10px;
            }
        }
        h2{
            margin-top: 30px;
        }
    }
}
@primary-color: #1DA57A;