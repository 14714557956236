.WhiteListHistory {
    display: flex;
    flex-flow: column;
    height: 100%;

    .VatDetails-box {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 0px 12px #e8e8e8;
        border-radius: 5px;
        height: 100%;
        max-height: calc(100% - 70px);
        position: relative;

        .ant-tabs-nav-wrap {
            //padding-right: 20px;
        }

        .ant-tabs {
            width: 100%;
            color: #233b53;
            height: 100%;
            overflow-y: auto;

            .hasError {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    background: url("../../../images/uwaga.svg") center no-repeat;
                    width: 20px;
                    height: 20px;
                    top: -5px;
                    right: -30px;
                }
            }

            .ant-form-item-children-icon {
                display: none;
            }
        }

        .ant-tabs-content {
            height: 100%;
        }

        .ant-tabs-nav {
            max-width: 100%;
            width: fit-content;
            margin: 20px 0 0 75px;
            box-sizing: border-box;
            min-width: 360px;

            &::before {
                border-bottom: 4px solid #e6e6e6;
                border-radius: 5px;
            }

            .ant-tabs-tab {
                width: 200px;
    
                margin: 0;
                padding: 8px 0 13px;
            }

            .ant-tabs-tab-btn {
                font-size: 14px;
                line-height: 19px;
                font-weight: bold;
                color: #233b53;
                width: 100%;
                text-align: center;
            }

            .ant-tabs-ink-bar {
                height: 4px;
                background-color: #EB3B5A;
                border-radius: 4px;
                min-width: 180px;
            }
        }

        .edit-sales {
            width: 100%;

            form {
                height: 100%;
            }

            .icon-holder {
                display: flex;
                justify-content: space-between;
                width: calc(100% / 3 - 100px);
                min-width: calc(100% / 3);
                align-items: center;
                min-height: auto;
                justify-content: space-between;
                position: relative;

                .details-item {
                    width: 100% !important;
                }

                img {
                    cursor: pointer;
                }
            }

            .ant-tabs-nav {
                margin: 20px 0 40px 40px;
                min-width: 620px;

                .ant-tabs-nav-list {
                    width: 100%;
                    justify-content: space-between;
                }
            }

            .ant-form-item-explain {
                position: absolute;

                div {
                    white-space: nowrap;
                }
            }

            .ant-select {
                max-width: 50%;
                width: 150px;
            }

            .ant-select-arrow {
                width: auto;
                right: 15px;
            }

            .ant-select-selection-item {
                height: 30px;
                display: flex !important;
                align-items: center;
            }

            .ant-input-affix-wrapper {
                padding-right: 10px !important;
            }

            input,
            .ant-input-affix-wrapper {
                max-width: 150px;
                height: 30px;
                width: fit-content;
                font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0;
                border-radius: 5px;

                &::after {
                    content: none;
                }
            }

            .edit-wrapper {
                padding: 0 30px;

                .sales-edit-form,
                .transaction-edit-form {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                }

                .transaction-edit-form {
                    width: 100%;

                    .has-dash {
                        margin-top: 40px;
                    }

                    .ant-form-item-explain {
                        // position: static;
                        // bottom: 0;
                        // div {
                        //   white-space: pre-wrap;
                        // }
                    }

                    .ant-form-item-explain {
                        bottom: -100%;
                    }

                    .transaction-row {
                        width: 100%;
                        margin-bottom: 20px;

                        .ant-input-suffix {
                            position: absolute !important;
                            right: 10px !important;
                            width: 10px !important;
                            height: 30px !important;

                            img {
                                position: static !important;
                                width: 10px;
                                height: 10px;
                            }
                        }

                        &.custom {
                            .ant-form-item-control-input {
                                width: 100%;
                            }
                        }

                        .ant-form-item {
                            height: auto;
                            min-height: inherit;
                            position: relative;

                            .ant-form-item-control-input {
                                width: 100%;
                            }
                        }
                    }

                    .ant-form-item-control {
                        align-items: flex-start;
                        max-width: 100%;
                    }

                    input {
                        width: 100%;
                        min-width: 100%;
                    }
                }

                .details-box-default {
                    padding: 0 20px 0;
                }

                .ant-picker-clear {
                    width: auto;
                    right: 10px;
                }

                .ant-select-selector,
                .ant-form-item-control-input-content input {
                    padding: 0 0 0 10px;
                }

                .ant-select-clear {
                    width: 20px;
                }

                .ant-picker-suffix {
                    display: none;
                }

                .ant-form-item-control {
                    max-width: 50%;
                }

                .ant-picker {
                    height: 30px;
                    border-radius: 5px;
                    max-width: 150px;
                    padding: 0;

                    &:hover {
                        border-color: #233b53;
                    }
                }

                .ant-picker-focused {
                    border-color: #233b53;
                    box-shadow: none;
                }

                .details-item {
                    width: calc(100% / 3 - 100px);
                    min-width: calc(100% / 3);
                    align-items: center;
                    min-height: auto;
                    justify-content: space-between;
                }

                .ant-form-item-control-input-content,
                .ant-input-affix-wrapper {
                    display: flex;
                    align-items: center;
                    min-height: auto;
                    justify-content: space-between;
                    max-width: 100px;
                }

                .ant-form-item-control-input-content {
                    display: flex;
                    align-items: center;
                }

                .ant-form-item-label {
                    text-align: left;
                    width: 50%;
                    min-width: 50%;
                    max-width: 50%;

                    font-size: 12px;
                    font-weight: bold;

                    label {
                        color: #233b53;
                    }

                    &>label::after {
                        content: none;
                    }
                }

                .ant-form-item-children-icon {
                    right: 10px;
                }

                .ant-form-item-label {
                    text-align: left;
                    width: 50%;
                    min-width: 50%;
                    max-width: 50%;

                    font-size: 12px;
                    font-weight: bold;

                    label {
                        color: #233b53;
                    }

                    &>label::after {
                        content: none;
                    }
                }

                .ant-form-item-children-icon {
                    right: 10px;
                }

                .custom {
                    &:not(.transaction-row) {
                        padding: 0;
                        box-sizing: border-box;
                        width: calc(100% / 3 + 10px);
                        justify-content: inherit;

                        span {
                            &:first-of-type {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .details-box-default {
                    padding: 0;
                }

                .transaction {
                    padding: 0 20px 0px;
                }

                .gtu-row {
                    display: flex;

                    .details-item {
                        margin-bottom: 0;

                        input {
                            height: auto;
                        }
                    }

                    .ant-row {
                        width: 100%;
                        justify-content: flex-start;

                        label {
                            display: flex;
                            align-items: center;

                            .ant-checkbox {
                                height: 15px;
                                width: 15px;
                                top: 0;
                            }

                            span {
                                &:last-of-type {
                                    display: flex !important;
                                    width: 100%;
                                    max-width: 100%;

                                    span {
                                        &:last-of-type {
                                            width: 100%;
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    span {
                        margin-right: 10px;

                        &:first-of-type {
                            font-weight: bold;
                        }

                        &:nth-of-type(2) {
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: calc(100% - 80px);
                        }
                    }
                }
            }
        }

        .represent {
            span {
                &:first-of-type {
                    font-weight: bold;
                }

                margin-right: 10px;
            }

            img {
                width: 15px;
                height: 15px;
            }
        }

        .details-box-default {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 55px 20px 0;
            height: auto;
        }

        .details-item {
            width: calc(50% - 40px);
            display: flex;
            margin-bottom: 30px;
            align-items: flex-end;

            &.present {
                span {
                    width: 50%;
                    color: #233b53;
                    font-size: 12px;

                    &:first-of-type {
                        font-weight: bold;
                    }

                    &:nth-of-type(2) {
                        display: block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }

            span {
                &:nth-of-type(2) {
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .details-box {
            width: 100%;
            margin-top: 40px;
            height: calc(100% - 100px);
            padding: 15px 30px;
            border-radius: 5px;
            box-shadow: 0px 0px 24px #233b531f;
            z-index: 10;

            .ant-tabs {
                height: 100%;

                .ant-tabs-nav {
                    margin: 0 0;
                    width: 100%;
                    margin-bottom: 20px;
                    max-width: 600px;

                    .ant-tabs-nav-list {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }
    }

    .Details {
        display: flex;
        margin-top: 50px;
        padding: 0px 0 0px 75px;

        .details-col {
            .inner-row {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                margin-bottom: 30px;

                p {
                    color: #707070;
                }

                .ant-row,
                .ant-select {
                    min-height: inherit;
                    position: relative;
                    right: 10px;
                }

                .ant-input-number {

                    .ant-input-number-handler-down,
                    .ant-input-number-handler-up {
                        background-image: url(../../../images/icons/select_arrow.png);
                    }
                }

                .ant-form-item-explain {
                    // position: absolute;
                    top: 42px;
                    left: -5px;
                }

                .nip {
                    margin-right: 15px;
                }
            }

            .inner-row__edit {
                margin-bottom: 10px;
            }

            .inner__bottom {
                position: relative;
                bottom: -93px;

                p {
                    flex-basis: 31%;
                }
            }
        }

        .details-col__first {
            width: 38%;

            p {
                flex-basis: 34%;
            }
        }

        .details-col__second {
            width: 27%;

            p {
                flex-basis: 60%;
            }
        }

        .details-col__third {
            width: 35%;

            p {
                flex-basis: 36%;
            }
        }

        @media only screen and (min-width: 1367px) {
            max-width: 1215px;
        }
    }
}

.EntityPanel {
    background: #fff;
    border-radius: 5px;
    padding: 0;
    box-shadow: 0px 0px 12px #e8e8e8;
    height: calc(100% - 70px);
    position: relative;

    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .lds-ring {
        top: 40%;
    }

    @media (min-width: 1920px) {
        height: calc(100% - 100px);
    }
}

.header-info {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #dedede;
    padding: 0 10px;
    box-sizing: border-box;

    &>* {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        max-width: 100px;
        text-align: center;
        user-select: none;

        &:first-of-type {
            min-width: fit-content;
        }

        &:not(:first-of-type) {
            margin-right: 10px;
            background: #fff;
            height: 40px;
            padding: 0 15px;
            cursor: pointer;
        }
    }

    .active {
        font-weight: bold;
    }
}

.active-data-wrapper {
    display: flex;
    width: 100%;
    padding: 0 10px;
}

.active-data-list {
    list-style: none;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    span {
        height: 20px;
        align-items: center;
        display: flex;

        &:first-of-type {
            min-width: 170px;
        }
    }
}

.go-back {
    position: absolute;
    right: -10px;
    background: #fff;
    width: 50px;
    display: flex;
    justify-content: center;
    height: 40px;
    cursor: pointer;
}

.transaction {
    padding: 0 10px 0px;
    height: 100%;

    &:not(.scroll) {
        overflow-y: auto;
    }

    &.scroll {
        max-width: 70%;

        &.full-width {
            max-width: 100%;
        }
    }
}

.transaction-header {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
}

.transaction-header-values {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 11;
    padding: 10px 0;

    span {
        width: 50%;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
    }

    div {
        display: flex;
        width: 50%;
    }
}

.has-dash {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #707070;
    }
}

.transaction-row {
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &.checkbox {
        margin-top: 40px;
        margin-bottom: 20px !important;

        span.ant-checkbox {
            min-width: 20px;
            min-height: 20px;

            input {
                padding: 0;
                width: 20px;
                height: 20px;
            }

            .ant-checkbox-inner {
                padding: 0;
                width: 20px;
                height: 20px;
            }
        }
    }

    span {
        position: relative;

        img {
            position: absolute;
            left: 50px;
            height: 15px;
            width: 15px;
            top: calc(50% - 5px);
            cursor: pointer;
        }
    }

    &>span {
        width: 50%;
        display: flex;
        align-items: center;

        &:first-of-type {
            font-weight: bold;
            max-width: calc(40% - 20px);
        }
    }

    &.custom {
        &>span {
            max-width: calc(50% - 40px);
            width: 100%;
        }

        div {
            width: 25%;

            span:not(.ant-input-suffix) {
                &:first-of-type {
                    display: none;
                }

                &:last-of-type {
                    width: fit-content;
                }
            }
        }
    }

    div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            width: 50%;
            display: flex;
            align-items: center;
            font-weight: initial;
        }
    }
}

.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background: #233b53 !important;
    }
}

.ant-picker-header-view button:hover {
    color: #233b53 !important;
}

.ant-picker-cell-inner::before {
    border: 1px solid #233b53 !important;
}

.ant-checkbox-group {
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 20px;
    }
}

.ant-checkbox-checked {
    &::after {
        border: 1px solid #233b53;
    }

    .ant-checkbox-inner {
        background-color: #fff;
        border-color: #233b53;

        &::after {
            border: 2px solid #233b53;
            border-top: 0;
            border-left: 0;
            left: calc(50% - 3px);
        }
    }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #233b53;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.main-tab-panel {
    .ant-tabs-nav-list {
        min-width: 540px;
    }
}

.sales-edit-form,
.transaction-edit-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.withWarning {
    position: relative;

    img {
        position: absolute;
        right: -22px;
    }
}

@primary-color: #1DA57A;//ToDo
@primary-color: #1DA57A;