.ClientEdit {
  width: 350px;
}

.ClientEdit .ant-form-item-required {
  font-size: 15px;
}

.ClientEdit .ant-input {
  font-size: 14px;
}

@primary-color: #1DA57A;