.ChangeEmail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangeEmail form .ant-form-item-label > label {
  font-size: 20px;
}

.ChangeEmail form .ant-input {
  font-size: 13px !important;
}

.ChangeEmail .create {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    margin: 0 30px 0 0;
  }
}

.ChangeEmail .change-password,
.ChangeEmail .logo,
.ChangeEmail h1 {
  margin-left: 33.33333333%;
}

.ChangeEmail a {
  color: mediumblue;

  &:hover {
    color: yellow;
  }
}

.ChangeEmail .login-button-container {
  margin: 10px 0 20px 0;
}

.ChangeEmail .edit-action-buttons {
  display: flex;
}

@primary-color: #1DA57A;