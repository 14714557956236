.FormModal.vatUEKForm .ant-modal-content {
    width: 100%;

    h3 {
        font-size: 18px;
        color: #233B53;
        text-align: center;
    }

    .ant-modal-body {
        p {
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
        }
        padding: 45px 2px 45px 10px;
        width: 100%;
    }

    .ant-form {
        width: 100%;
    }

    .vatUEKmodal {
        width: 100%;
        margin-bottom: 30px;
    }

    .ReactTable .rt-thead .rt-tr .rt-th {
        color: #707070;
        font: normal normal bold 14px/18px Lato;
        margin-right: 10px !important;
    }

    .ReactTable.clean .rt-tbody .rt-td {
        padding-left: 0px !important;
        font: normal normal bold 14px/18px Lato;
    }

    .ReactTable.clean .rt-tbody .rt-td::after {
        width: 0px;
    }

    .ReactTable .rt-tbody .rt-tr-group {
        border-bottom: 0px !important;
    }

    .ReactTable .rt-thead .rt-tr {
        border-bottom: 0px !important;
    }
}
@primary-color: #1DA57A;