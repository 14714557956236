.ChangePassword,
.ChangeEmail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  &::before {
    position: absolute;
    top: 10px;
    left: 10px;
    content: "";
    background: url("../../images/four_dots.svg") center no-repeat;
    width: 20px;
    height: 20px;
  }

  &::after {
    position: absolute;
    bottom: 10px;
    right: 10px;
    content: "";
    background: url("../../images/four_dots.svg") center no-repeat;
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
}

.ChangePassword form,
.ChangeEmail form {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 40px 60px;
}

.ChangePassword form .ant-row,
.ChangeEmail form .ant-row {
  width: 100%;
}

.ChangePassword form .ant-row .ant-col-16,
.ChangeEmail form .ant-row .ant-col-16 {
  max-width: 100%;
}

.ChangePassword .edit-action-buttons {
  // margin-top: 40px;
}

.ChangePassword .ant-form-item-explain {
  max-width: 400px;
}

@primary-color: #1DA57A;