@import "../../styles.less";

.Dashboard {
  background-color: #f4f4f4;
  // max-height: calc(100% - 50px);
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.Dashboard-app-switcher {
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  grid-gap: 20px;
  padding: 20px;
  border-radius: 10px;
}

.Dashboard-app-switcher a {
  width: 100%;
}

.Dashboard-app-switcher .Dashboard-app {
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: #233b53;
  text-align: center;
  box-shadow: 0px 0px 12px #4b65841f;
  padding: 27px 30px;
  transition: transform 250ms ease-in-out;
  will-change: transform;
  max-width: 845px;

  &:hover {
    transform: translateY(-3px);
  }
}

.Dashboard-app-switcher .Dashboard-app .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Dashboard-app .content img {
  box-shadow: 0px 0px 24px #4b65841f;
  border-radius: 5px;
  padding: 3% 10%;
  max-width: 240px;
}

.Dashboard .Dashboard-app h4 {
  .caption-5();
  user-select: none;
  margin: 0;
}

.Dashboard-app-switcher .Dashboard-app .left {
  max-width: 255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.Dashboard-app-switcher .Dashboard-app .left p {
  .body-2();
}

.Dashboard-app-switcher .Dashboard-app .header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.Dashboard-app-switcher .Dashboard-app .header .char {
  border: none;
  width: 32px;
  height: 32px;
  color: white;
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 22px;
  outline: none;
}

@media (min-width: 1920px) {
  .Dashboard-app-switcher {
    grid-gap: 30px 60px;
    padding: 30px 60px;
  }

  .Dashboard-app .content img {
    width: 285px;
    min-height: 170px;
    height: 100%;
    max-width: 340px;
  }

  .Dashboard-app-switcher .Dashboard-app {
    padding: 40px 45px 40px 60px;
  }

  .Dashboard-app-switcher .Dashboard-app .header {
    margin-bottom: 45px;
  }

  .Dashboard-app-switcher .Dashboard-app .content {
    align-items: center;
  }
}

@primary-color: #1DA57A;