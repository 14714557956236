.selectVerificationModal {
    .selectVerificationModalContent {
        display: flex;
        flex-direction: column;
        width: 400px;
        align-items: center;

        h3 {
            font-size: 18px;
            color: #233B53;
        }

        span {
            font-size: 14px;
            font-weight: bold;
            color: #233B53;
        }
        #SelectVerificationForm{
            width: 360px;
            padding-bottom: 10px;
        }
    }
}

.ant-modal.FormModal.selectVerificationModal {
    .ant-modal-content {
        .ant-modal-body {
            width: 490px;
        }
    }
}
@primary-color: #1DA57A;