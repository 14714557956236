.Sidebar {
  position: absolute;
  height: 100vh;
  z-index: 998;
  background-color: white;
  // box-shadow: 0px 0px 35px #4b65841f;
  z-index: 999;
}

.Sidebar .hamburger-wrapper {
  padding: 0 20px;
  border: none;
  // height: 100%;
  height: 70px;
  background: white;
  cursor: pointer;
  outline: none;
}

.Sidebar .Navbar-hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5b6d7f;
  width: 35px;
  padding: 13px 6px;
  border-radius: 5px;
}

.Sidebar .topButtons {
  background: none;
}

.Sidebar .sidebar-content {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
}

.topButtons .anticon svg,
.bottomButtons .anticon svg {
  color: #5b6d7f;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  // padding-bottom: 30px;
}

.ant-layout-sider-collapsed .ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
  width: auto;
}

.__react_component_tooltip {
  opacity: 1 !important;
}

.ant-layout-sider-collapsed .ant-menu-item {
  justify-content: center;
  display: flex;
  padding: 0 !important;
  align-items: center;
}

.Sidebar .ant-menu-item {
  padding-left: 24px;
  display: flex;
  align-items: center;
}

.Sidebar .ant-menu-item .anticon + span {
  font-size: 15px;
}

.Sidebar .ant-menu-item span {
  display: flex;
}

.ant-layout-sider-trigger {
  top: 60px;
}

.ant-layout-sider-has-trigger {
  padding: 108px 0 0 0;
}

.ant-menu-inline-collapsed {
  width: auto;
}

.Sidebar .bottomButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}

.Sidebar .bottomButtons .tooltip {
  margin-bottom: 20px;
}

.Sidebar .bottomButtons button {
  border: none;
}

.topButtons {
  .ant-menu-item-selected {
    position: relative;
    &::after {
      position: absolute;
      right: 0;
      content: "";
      width: 4px;
      height: 22px;
      background-color: #f7b731;
      top: 5px;
    }
  }
}

.wlTopButtons{
  .ant-menu-item-selected {
    &::after {
      background-color: #ea3d5b;
    }
  }
}

.nipTopButtons{
  .ant-menu-item-selected {
    &::after {
      background-color: #8854D0;
    }
  }
}

.tooltip {
  max-width: 180px;
  line-height: 16px;
}

.tooltip-vat {
  max-width: 330px;
  line-height: 16px;
}

.tooltip-header {
  font: normal normal bold 12px/16px Lato;
  color: #163567;
  display: block;
  margin-bottom: -10px;
}

.tooltip-content {
  font: normal normal normal 12px/16px Lato;
  color: #163567;
  margin-top: 10px;
}

@primary-color: #1DA57A;