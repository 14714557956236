// FONT STYLES
.heading-1 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 900;
}

.heading-2 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 300;
}

.heading-3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
}

.heading-4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.body-1 {
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.body-2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.body-2-bold {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.body-2-black {
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
}

.caption-1 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.caption-2 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

.caption-3 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.caption-4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 300;
}

.caption-5 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
}

// BUTTONS
// regular buttons
.button-large,
.button-large-white,
.button-small,
.button-small-white {
  background-color: #4b6584;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  width: 140px;
  height: 42px;
  border-radius: 5px;
  border: none;

  &:active,
  &:focus {
    background-color: #4b6584;
  }

  &:hover {
    background-color: #5b7798;
    border: none;
  }

  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 900;
  }
}

.button-small-white,
.button-large-white {
  border: 2px solid #4b6584;
  background: none;

  span {
    color: #4b6584;
  }

  &:hover,
  &:active,
  &:focus {
    background: none;
    border: 2px solid #4b6584;

    span {
      color: #5b7798;
    }
  }
}

.button-large[disabled],
.button-large[disabled]:hover {
  background-color: #dddddd;
}

.button-large span,
.button-large-white span {
  text-transform: initial;
}

.button-small span,
.button-small-white span {
  text-transform: capitalize;
}

// OTHER GLOBAL STYLES
html,
body {
  font-family: "Lato";
}

#root {
  color: #233b53;
  height: auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #233b53;
  }

  ::selection {
    background: #a5b1c2;
  }
}

.ant-input-number {
  height: 38px;
  width: 106px;
  border: 1px solid #dddddd;
  border-radius: 5px;

  .ant-input-number-input-wrap {
    height: 100%;

    input {
      height: 100%;
      color: #233b53;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding-left: 10px;
    }
  }

  .ant-input-number-handler-wrap,
  .ant-input-number-handler-down,
  .ant-input-number-handler-up {
    border: none;
  }

  .ant-input-number-handler-down,
  .ant-input-number-handler-up {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;

    span {
      display: none !important;
    }
  }

  .ant-input-number-handler-up {
    transform: rotate(180deg);
  }
}

.ant-input,
.ant-input-password {
  border-radius: 5px;
  border-color: #dddddd;
  box-sizing: border-box;
  color: #233b53;
}

.ant-input {
  height: 45px;
  font-size: 14px;
  font-weight: 600;
}

.ant-input-password {
  height: 43px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-input-password .ant-input {
  height: 39px;
}

.ant-row {
  height: auto;
  min-height: 75px;
  margin: 0;
}

.login-link {
  color: #2a9cb3;

  &:hover {
    color: #2a9cb3;
    text-decoration: underline;
  }
}

.ant-form-item-control-input-content input,
.ant-input-password {
  padding-left: 20px;
  padding-right: 10px;
}

.ant-form-item-explain {
  .caption-3();
  margin-top: 2px;
  margin-bottom: 10px;
}

.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.7);
}

// custom error handling in forms
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  // color: white;
  // background-color: red;
  // animation-name: diffZoomIn2 !important;
  // width: 19px;
  // height: 19px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border-radius: 50%;
  // right: -30px;

  display: inline-block;
  right: -35px;
  z-index: 5;
  width: 17px;
  height: 17px;

  color: #fff;
  background: red;
  border-radius: 100%;
  border: 1px solid red;
  box-sizing: content-box;
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon svg,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon svg,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon svg,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon svg {
  width: 17px;
  height: 17px;
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: none;
}

.ant-menu-dark,
.ant-menu-item-selected {
  background: none !important;
}

.ant-select-selector {
  height: 38px !important;
  border-radius: 5px !important;
  border-color: #dddddd !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:focus {
    border: 1px solid #233b53 !important;
  }
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused,
.ant-input-number-focused,
.ant-input-number:focus,
.ant-input-number:hover,
.ant-select-selector {
  border: 1px solid #233b53;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused {
  border-color: #233b53;
  box-shadow: none;
}

.ant-select-item-option-active,
.ant-select-item-option,
.ant-select-item,
.ant-select-item-option-selected {
  background-color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: #233b53;
}

.ant-select-item-option-selected {
  color: #233b53 !important;
}

.ant-select-selection-search-input {
  height: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 0 2px 10px !important;
}

.ant-select-item-option-selected {
  color: #233b53 !important;
  background-color: #f5f5f5 !important;
}

.ant-select-selection-search-input {
  height: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 0 2px 10px !important;
}

.ant-form-item-has-error .ant-input,
.ant-input-password .ant-input-affix-wrapper {
  border-width: 1px;
}

.ant-form-item-has-error .ant-input:focus {
  box-shadow: none;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 0;
}

input[type="password"] {
  letter-spacing: 4px;

  &::placeholder {
    letter-spacing: 0;
  }
}

p {
  margin: 0;
}

.ant-form-item-has-feedback .ant-input {
  padding-left: 15px;
}

.ant-form-item-control-input-content input,
.ant-input-password {
  padding-left: 15px;
}

.ant-btn {
  &::after {
    content: none;
  }
}

.underline {
  text-decoration: underline;
}

.ant-select {
  color: inherit;
}

// Custon Ant-Select DropDown styles

// ewentualne style dla ant selectDropdown custom scrool

// .ant-select-dropdown {
//   &::-webkit-scrollbar {
//     width: 12px;
//     height: 1px;
//   }
//   &::-webkit-scrollbar-thumb {
//     height: 6px;
//     border: 4px solid rgba(0, 0, 0, 0);
//     background-clip: padding-box;
//     -webkit-border-radius: 7px;
//     background-color: #e6e6e6;
//     -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
//       inset 1px 1px 0px rgba(0, 0, 0, 0.05);
//   }
//   &::-webkit-scrollbar-button {
//     width: 0;
//     height: 0;
//     display: none;
//   }
//   &::-webkit-scrollbar-corner {
//     background-color: transparent;
//   }
// }
