.Addentity-form {
  background-color: #ffffff;
  display: flex;
  flex-flow: column;

  .Addentity-title {
    margin: 0 auto 25px;
    max-width: 250px;
    color: #233b53;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  .Addentity-sections {
    padding: 0 30px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-row {
      min-height: 50px;
      display: flex;
      align-items: center;

      .ant-input-number-handler-down,
      .ant-input-number-handler-up {
        background-image: url(../../images/icons/select_arrow.png);
      }

      .ant-select-arrow {
        right: 10px !important;
        top: 18px;
      }

      .ant-form-item-children-icon {
        display: none;
      }

      .ant-form-item-explain {
        margin-top: 8px;
        margin-bottom: 7px;
      }

      &:last-of-type {
        margin-top: 10px;
        align-items: flex-start;
      }
    }

    label {
      font: normal normal bold 14px/18px Lato;
      letter-spacing: 0px;
      color: #707070;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;

      &:before {
        content: none !important;
      }
    }
  }

  .Addentity-buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0 57px;
    margin-top: 18px;

    button {
      width: 109px;
      height: 36px;
    }

    .ant-btn[disabled] {
      background-color: #dddddd;
      color: #ffffff;
    }

    .ant-spin-dot {
      position: relative;
      left: -10px;
    }
  }
}

.Addentity-form .ant-input-number-handler-wrap {
  display: block;
}

.Addentity-form .ant-anchor-wrapper {
  background-color: inherit;

  .ant-anchor {
    padding-left: 0;

    .ant-anchor-ink:first-of-type {
      display: none;
    }
  }

  .ant-anchor-link {
    padding: 4px 0;
  }

  .ant-anchor-link-title {
    white-space: pre-line;
  }
}

.nosubject-info {
  text-align: center;
  font-size: 16px;
  color: #233b53;
  font-weight: bold;
  min-height: 335px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  p {
    margin-bottom: 30px;
  }
}

@primary-color: #1DA57A;