.navigation {
  background: #fff;
  border-radius: 5px;
  height: 15%;
  min-height: 120px;
  box-shadow: 0 0 5px 0 #dedede;
  width: 100%;

  align-items: center;
  display: flex;
  padding-left: 45px;
  justify-content: space-between;
}

@primary-color: #1DA57A;