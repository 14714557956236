@import "../../styles.less";

.ResetPassword-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 117px;
}

.ResetPassword {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding-top: 179px;
  width: 100%;
}

.ResetPassword form {
  width: 281px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ResetPassword form .ant-form-item-label > label {
  font-size: 20px;
}

.ResetPassword form .ant-input {
  font-size: 13px !important;
}

.ResetPassword form p {
  margin-bottom: 40px;
  .body-1();
}

.ResetPassword form .ant-form-item {
  margin-bottom: 44px;
  width: 200px;
}

.ResetPassword form .ant-col-16 {
  max-width: none;
}

.ResetPassword .login-button {
  width: 110px;
}

.ResetPassword .buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (min-width: 1920px) {
  .ResetPassword-container {
    padding-top: 160px;
  }

  .ResetPassword {
    padding-top: 179px;
  }
}

@primary-color: #1DA57A;