.client-action-button,
.client-edit-button,
.client-cancel-button {
  min-width: 100px;
  height: calc(100% - 20px);
  margin-right: 10px;
}

.client-cancel-button {
  background-color: darkred;

  &:hover {
    background-color: indianred;
  }
}

.client-action-button {
  margin-right: 5px;
}

@primary-color: #1DA57A;