@import "../../styles.less";

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: white;
  position: relative;
  z-index: 999;
  margin-left: 75px;
}

.ant-layout-header {
  background: none;
  padding: 0 24px 0 0;
}

.ant-layout-header .ant-menu {
  padding-left: 20px;
}

.Navbar .header-container {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.Navbar .header-container .char {
  border: none;
  width: 32px;
  height: 32px;
  color: white;
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 22px;
  outline: none;
}

.Navbar .Navbar-header {
  font-size: 25px;
  margin: 0;
  user-select: none;
  text-transform: uppercase;
  .heading-3();
}

.Navbar .Navbar-dropdown {
  height: auto;
  width: auto;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: #233b53;

    .Navbar-chevron-down,
    .Navbar-chevron-up {
      color: blue;
    }
  }
}

.Navbar .Navbar-arrow {
  font-size: 13px !important;
  height: 23px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.switcher-container {
  background-color: white;
  padding: 20px;
  padding-bottom: 31px;
}

.switcher-container .button-container .x {
  padding: 6px;
  margin-left: 4px;
  margin-bottom: 9px;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.switcher-container .button-container button {
  width: 18px;
  height: 18px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 400;
  font-size: 22px;
  transform: scale(1.4, 1);
  cursor: pointer;
  outline: none;
  box-sizing: content-box;
  border-radius: 5px;
  padding: 6px;
  margin-left: 4px;
  margin-bottom: 9px;

  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
}

.switcher-container .dashboard-app {
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 100%;
  text-align: start;
  background: none;
  text-transform: uppercase;
  outline: none;
  display: flex;
  align-items: center;
  color: #233b53;
  margin-bottom: 1px;
  .body-2-black();

  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
}

.switcher-container .current-app {
  border: 1px solid #bebebe;
}

.switcher-container .dashboard-app img {
  width: 27px;
  height: 17px;
  margin-right: 17px;
}

.Navbar-app-switcher {
  display: flex;
  flex-direction: column;
}

.Navbar-app-switcher .Navbar-app {
  height: 29px;
  display: flex;
  align-items: center;
  color: #233b53;
  text-align: center;
  padding: 6px 10px 6px 10px;
  box-sizing: content-box;
  border-radius: 5px;
  margin-bottom: 1px;
  .body-2-bold();

  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
}

.Navbar-app-switcher .Navbar-app .char {
  border: none;
  width: 29px;
  height: 29px;
  color: white;
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 19px;
}

.Navbar-dropdownImg {
  width: 80px;
  height: 40px;
  margin-right: 10px;
}

.buttonContainer {
  padding: 15px 20px;
  border-radius: 5px;
}

// Org Switcher
.orgSwitcherButton {
  .heading-3();
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: auto;
  background-color: white;
  border: none;
  outline: none;
  padding: 6px 13px 6px 13px;
  border-radius: 5px;
  cursor: pointer;
}

.orgSwitcherButton .char,
.switcherContent .inner .char {
  margin-right: 15px;
  border: 2px solid #233b53;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switcherContent {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
  padding: 17px 0;
  .heading-3();
}

.switcherContent .ant-menu-item:active,
.switcherContent .ant-menu-item:focus,
.switcherContent .ant-menu-item:hover,
.switcherContent .ant-menu-item-selected {
  background: none !important;
  outline: none !important;
  color: unset !important;
}

.switcherContent .item {
  height: auto;
  padding: 0px 10px 0 20px;
  border: 1px solid transparent;
}

.switcherContent .item .inner.currentOrg {
  border: 1px solid #bebebe;
  border-radius: 5px;
}

.switcherContent .inner {
  display: flex;
  align-items: center;
  padding: 6px 13px;
  text-transform: uppercase;

  &:hover {
    background-color: #f4f4f4;
    border-radius: 5px;
  }
}

@primary-color: #1DA57A;