@import "../../styles.less";

.logo {
  width: 400px;
}

.form-button {
  background-color: #233b53;
  border-radius: 5px;
  width: 100%;
  height: 42px;
  border: none;

  &:active,
  &:focus {
    background-color: #233b53 !important;
  }

  &:hover {
    background-color: #294562;
    border: none;
  }

  span {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
  }
}

.button {
  .button-small();
}

.form-button[disabled],
.form-button[disabled]:hover {
  background-color: #dddddd;
}

.form-button .ant-spin {
  margin-right: 10px;
  color: white;
}

.form-span {
  .body-2-black();
  color: white;
}

.left-side {
  margin-right: 185px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  &.center {
    align-items: center;
  }
  &.small {
    align-items: start;
    margin-left: 100px;
    img {
      width: 120px;
    }
  }
}

.left-side-small {
  margin-right: 185px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  align-items: start;
  margin-left: 70px;
  img {
    width: 150px;
  }
}

.left-side .top {
  // margin-right: 130px;
}

.left-side .login-heading {
  .heading-1();
  margin-bottom: 30px;
}

.left-side .subheader {
  display: flex;
  position: relative;
  padding-right: 15px;
  margin-bottom: 60px;
  justify-content: flex-end;
}

.left-side .subheader p {
  .caption-4();
}

.left-side .subheader p span {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #707070;
}

.left-side .subheader .icon {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -10px;
  right: 0;
}

.left-side .subheader .icon .redbox,
.left-side .subheader .icon .greenbox {
  width: 8px;
  height: 8px;
  border: none;
}

.left-side .subheader .icon .redbox {
  background-color: red;
}

.left-side .subheader .icon .greenbox {
  background-color: green;
}

.left-side .login-heading {
  font-size: 62px;
  font-weight: 900;
  line-height: 75px;
  margin-bottom: 30px;
  color: #233b53;
}

.left-side .subheader {
  display: flex;
  position: relative;
  padding-right: 15px;
  margin-bottom: 60px;
}

.left-side .subheader p {
  .caption-4();
  color: #233b53;
}

.left-side .subheader p span {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #707070;
  margin-left: 10px;
}

.left-side .subheader .icon {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -10px;
  right: 0;
}

.left-side .subheader .icon .redbox,
.left-side .subheader .icon .greenbox {
  width: 8px;
  height: 8px;
  border: none;
}

.left-side .subheader .icon .redbox {
  background-color: red;
}

.left-side .subheader .icon .greenbox {
  background-color: green;
}

.left-side img,
.left-side-small img {
  margin-top: 30px;
}

.FormModal {
  width: fit-content !important;
  padding: 0;
}

.FormModal .ant-modal-content {
  width: 420px;
  min-height: 190px;
}

.ant-modal-content {
  border-radius: 5px;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.FormModal .ant-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 45px 60px;
  height: 100%;
  position: relative;
  box-shadow: 0px 0px 35px #4b65841f;
  border-radius: 5px;
  background: #fff;
}

.FormModal .ant-modal-body p {
  margin-bottom: 30px;
  .body-2-bold();
}

.FormModal .ant-modal-close {
  display: none;
}

.FormModal .leftTopDots,
.FormModal .rightBottomDots {
  position: absolute;
}

.FormModal .leftTopDots {
  top: 10px;
  left: 10px;
}

.FormModal .rightBottomDots {
  bottom: 10px;
  right: 10px;
  transform: rotate(-180deg);
}

.FormModal .buttons {
  display: flex;
}

.FormModal .buttons .cancel {
  margin-right: 20px;
}

@media (min-width: 1920px) {
  .left-side {
    margin-right: 270px;
  }

  .left-side .chart {
    width: 453px;
    height: 419px;
  }

  .left-side .top {
    // margin-right: 260px;
  }
}

.left-side {
  &.custom {
    align-items: flex-start;
    padding: 50px 100px;
    .top {
      margin-right: 0;
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
      }
    }
    .subheader {
      margin: 0;
      margin-left: 20px;
      padding: 0;
      height: 100%;

      p {
        display: flex;
        white-space: nowrap;
        align-items: baseline;
      }
      .icon {
        right: -10px;
      }
    }
  }
}

.ant-input-number-borderless {
  border: none !important;
}

.lock-closed {
  width: 16px;
  height: 17px;
  background: url("../../images/lock_close.svg") center no-repeat;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: -6px;
  z-index: 1;
  zoom: 1.5;
}

.lock-open {
  width: 16px;
  height: 17px;
  background: url("../../images/lock_open.svg") center no-repeat;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: -6px;
  z-index: 1;
  zoom: 1.5;
}

.code-name {
  display: block !important;
  color: #0fb9b1;
  font-size: 12px;
  font-weight: initial;
}

.code-name-custom {
  display: block !important;
  color: #0fb9b1;
  font-size: 12px;
  font-weight: initial !important;
  position: absolute !important;
  left: -40px;
}

.btnDisabled {
  opacity: 0.2;
}

.transaction-row .ant-form-item-label {
  position: absolute;
  left: -50px;
}

.add-wrapper {
  display: flex;
  margin-bottom: 20px;
  .const-name {
    left: -50px;
  }
  .ant-form-item-explain {
    white-space: nowrap;
  }
  .checkbox {
    .ant-form-item-explain {
      position: absolute !important;
      top: 23px;
    }
  }
}

.full-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tooltip.type-custom {
  z-index: 9999999;
}

.tmp-image {
  margin-bottom: 20px;
  z-index: 999;
  width: 210px;
  height: 210px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 30px;
  border-radius: 100%;
  left: 0;
  right: 0;
  font: 74px / 89px Lato;
  letter-spacing: 0px;
  color: #233b53;
  box-shadow: 0px 0px 24px #233b531f;
  position: relative;
  overflow: hidden;
}

// .line {
//   width: 40px;
//   height: 1px;
//   background: #233b53;
//   margin: 0 auto;
// }

// .ReactTable .rt-thead.-header {
//   position: sticky;
//   top: 0;
//   background: #fff;
//   z-index: 999999;
// }

.gateExportModal {
  max-width: 550px;
  text-align: center;
  .buttons {
    margin-top: 0px !important;
  }
}

.vatTable_records,
.vatDetailsTable,
.wlTable_records {
  .rt-thead {
    top: 0;
    position: sticky;
    z-index: 999;
    background: #fff;
  }
}

@primary-color: #1DA57A;