.table {
  margin-top: 10px;
  background: #fff;
  border-radius: 5px;
  height: 120px;
  box-shadow: 0 0 5px 0 #dedede;
  height: calc(85% - 10px);
  width: 100%;
  min-height: 400px;

  padding: 0 40px;

  .gate-data-chart {
    max-width: 100%;
    display: flex;
    margin: 30px 0 0;

    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    overflow: hidden;
  }

  .dot-wrapper {
    display: flex;
    justify-content: flex-start;
    width: calc(100% / 5 - 30px);
    transform: translate(50%, 0px);

    &:not(:first-of-type) {
      .dot {
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-color: #dedede;
          display: block;
          position: absolute;
          top: 7px;
          left: calc(-100% - 5px);
        }

        &.active {
          &::after {
            background-color: #0fb9b1;
          }
        }
      }
    }
  }

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #dedede;

    &.active {
      background-color: #0fb9b1;
    }
  }
}

.gateTable {

  // table header
  .rt-thead .rt-tr .rt-th {
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  // no dash
  &.clean {
    margin-top: 20px;

    .rt-tbody .rt-td::after {
      content: none;
    }

    .rt-tbody .rt-td {
      text-align: center;
      font-size: 12px;
    }
  }

  .gateTableNameColumn {
    overflow: hidden;
    display: inline-block;
  }

  .ellipsis {
    text-overflow: ellipsis;
    max-width: 75%;
  }
}
@primary-color: #1DA57A;