.wl-table-details {
  width: 100%;
  //padding: 30px 50px;
  box-sizing: border-box;
  background: #fff;
  height: calc(100% - 70px);
  border-radius: 5px;
  box-shadow: 0px 0px 35px #4b65841f;
  position: relative;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  margin-top: 10px;
  margin-right: 25px;
  padding: 5px;
  border: none;
  right: 0px;
  left: auto;
  border: none;
  background: transparent;
  img {
      height: 10px;
      width: 10px;
  }
}

.wl-details {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  span.details-header {
    font: normal normal bold 14px/18px Lato;
    padding-left: 30px;

    margin-bottom: 20px;
  }


  span.label {
    font: normal normal bold 12px/18px Lato;

    color: #707070;

    &:first-of-type {
      color: #233b53;
      min-width: 150px;
      margin-right: 110px;
    }

    &:last-of-type {
      // display: block;
      // text-overflow: ellipsis;
      white-space: nowrap;
      // overflow: hidden;
    }
  }

  ul {
    padding-left: 30px;
  }

  li {
    display: flex;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}

.ant-drawer-body {
  display: flex;
  align-items: center;
  justify-items: center;
  min-width: 135px;
}

// .wl-status-button{
//   min-width: 135px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.ant-drawer-content-wrapper {
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #E8E8E8;
}

.wl-import-status {
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 135px;
}

.wl-status-symbol {
  color: #00A74F;
  margin-right: 0px;
  padding-left: 5px;
  ;
}

.wl-status-dropdown-row-symbol-checked {
  visibility: visible;
}

.wl-status-dropdown-row-symbol-unchecked {
  visibility: hidden;
}

.wl-status-error {
  color: #233B53;
}

.wl-status-right {
  color: #00A74F;
}

.wl-status-wrong {
  color: #FF0000;
}

.wl-status-selected {
  background-color: #F4F4F4 !important;
}

.wl-status-button:hover {
  border: 1px solid #DDDDDD;
  color: #233B53;
}

.wl-status-button:focus {
  border: 1px solid;
  color: #233B53;
  border-color: #DDDDDD;
}

.ReactTable {
  .rt-tr {
    .rt-th {
      div {
        &::after {
          top: 5px;
        }
      }
    }
  }
}

#page-size {
  background: none;
}

#wl-status-dropdown {
  color: #233B53;
}


.templateModal {
  h3 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    text-align: center;
    color: #233b53;
  }

  button {
    width: 139px;
    height: 36px;

    span {
      text-transform: inherit;
    }
  }

  .ant-modal-body {
    padding-top: 44px;
    padding-left: 72px;
    padding-right: 72px;
  }

  .ant-modal-content {
    width: 495px;
    height: 299px;
    font-size: 24px;
  }

  .ant-radio-wrapper span {
    font-size: 14px;
    color: #233B53;
    font-weight: bold;
  }

  .ant-radio-input {
    color: #233B53;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #233B53 !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #233B53;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #233B53;
  }

  .templateTypeName {
    margin-left: 20px
  }
}


.wl-imports-dropdown-content {

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DDDDDD;
  opacity: 1;
  width: 295px;
  max-width: 400px;
  min-height: 215px;

  .broken-search {
    height: 38px;
    margin: 10px 15px 10px 15px;
  }

  .wl-imports-dropdown-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
  }

  .wl-imports-dropdown-row-checked {
    background-color: #F4F4F4 !important;
  }

  .ant-btn.ant-dropdown-trigger.wl-status-button.wl-imports-dropdown {
    font-weight: bold;
  }

  .wl-imports-dropdown-inner-span {
    margin-left: 15px;
  }

  .wl-imports-dropdown-row-symbol {
    color: green;
    margin-left: 5px;
  }

  .wl-imports-dropdown-row-symbol-checked {
    visibility: visible;

  }

  .wl-imports-dropdown-row-symbol-unchecked {
    visibility: hidden;
  }

  .ant-menu-item-selected {
    color: #233B53;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #233B53;
  }
}

.wl-imports-dropdown-inner-selected-list {
  max-height: 150px;
  overflow: auto;
  overflow-x: unset;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.NipVerifierEntityPanel {
  background: #fff;
  border-radius: 5px;
  padding: 0;
  box-shadow: 0px 0px 12px #e8e8e8;
  height: calc(100% - 70px);
  position: relative;

  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .lds-ring {
    top: 40%;
  }

  @media (min-width: 1920px) {
    height: calc(100% - 100px);
  }
}

.NipVerifierTableWithDetails {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.NipVerifierSelectionBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 42px;
  margin-top: 20px;
  font-weight: bold;
}

.NipVerifierSelectionButton{
  text-decoration: underline;
  cursor: pointer;
}

.NipVerifierSelectionCounter{
  margin-left: 40px;;
}
@primary-color: #1DA57A;