@import "../../styles.less";

.Login-container {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 140px;
  &.landing-page {
    height: 100vh;
  }
}

.header-section {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 30px;
  display: block;
}

.Login {
  margin-top: 179px;
  height: 100%;
  width: 100%;
  position: relative;
}

.Login form {
  width: 320px;
  position: relative;
}

.Login form .ant-form-item-label > label {
  font-size: 20px;
}

.Login .change-password,
.Login .logo,
.Login h1 {
  margin-left: 33.33333333%;
}

.Login .login-button-container {
  margin: 35px 0 43px 0;
  height: auto;
}

.Login .errorMessage {
  color: #ff0000;
  margin: 0;
  position: absolute;
  top: -50px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

.Login .reset-container {
  display: flex;
  justify-content: flex-end;
}

.Login .create {
  text-align: center;
}

.LoginModal .username-span {
  color: blue;
}

@media (min-width: 1920px) {
  .Login-container {
    padding-top: 160px;
  }

  .Login {
    margin-top: 270px;
  }
}

@primary-color: #1DA57A;