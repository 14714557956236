.content-wrapper {
  background: #f4f4f4;
  padding: 20px 30px 30px 30px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  @media (min-width: 1920px) {
    padding: 30px 50px 30px 50px;
  }
}

.inner-shadow {
  box-shadow: inset 0px 0px 10px #dedede;
  border-radius: 0;
}

.ClientPanel {
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 0px 12px #e8e8e8;
  height: 100%;

  // &.details-visible {
  //   padding: 30px 0 30px 30px;
  // }

  &.with-dots-1 {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      bottom: 0px;
      left: 40px;
      background: url("../../images/dots02.svg") center no-repeat;
      width: 80px;
      height: 120px;
    }
    &::before {
      position: absolute;
      content: "";
      top: 160px;
      right: 0px;
      background: url("../../images/dots01.svg") center no-repeat;
      width: 120px;
      height: 80px;
    }
  }
  &.with-dots-2 {
    &::before {
      position: absolute;
      content: "";
      top: 0px;
      right: 140px;
      background: url("../../images/dots01.svg") center no-repeat;
      width: 120px;
      height: 80px;
    }
  }
  &.with-dots-3 {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      bottom: 10px;
      left: 170px;
      background: url("../../images/dots04.svg") center no-repeat;
      width: 60px;
      height: 100px;
    }
    &::before {
      position: absolute;
      content: "";
      top: 160px;
      right: 10px;
      background: url("../../images/dots05.svg") center no-repeat;
      width: 100px;
      height: 60px;
    }
  }
  @media (min-width: 1920px) {
    padding: 40px 55px;
    &.with-dots-1 {
      &::after {
        bottom: 30px;
        left: 80px;
      }
      &::before {
        right: 30px;
      }
    }
  }
}

.ClientPanel .nip-info {
  visibility: hidden;
  display: none;
}
.ClientPanel .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  span {
    color: #233b53;
    font-size: 18px;
    text-transform: uppercase;
    font: normal normal bold 18px/24px Lato;
  }
  .actions {
    // height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    .icon-box {
      margin-left: 20px;
      min-width: 23px;
      img {
        min-width: 21px;
      }
    }

    .action-icon {
      margin-left: 20px;
    }
  }
}

.ClientPanel .content {
  .image-container {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    height: 115px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 115px;
    margin: 0 auto;
    transform: translateX(-30px);
    border-radius: 100px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #4b65841f;
    font: normal normal bold 18px/24px Lato;
    letter-spacing: 0px;
    color: #233b53;
    text-transform: uppercase;
  }
  &.has-before {
    max-width: 370px;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    padding-top: 180px;
    position: relative;
    @media (min-width: 1920px) {
      margin-top: 80px;
      padding-top: 260px;
      &::before {
        height: 160px;
        max-width: 160px;
        padding: 20px;
      }
    }
  }

  span.label {
    font: normal normal bold 14px/18px Lato;
    color: #233b53;
    text-transform: uppercase;

    &:first-of-type {
      color: #707070;
      text-transform: none;
      min-width: 150px;
      margin-right: 90px;
    }

    &:last-of-type {
      // display: block;
      // text-overflow: ellipsis;
      white-space: nowrap;
      // overflow: hidden;
    }
  }
  li {
    display: flex;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}

.ClientPanel .ant-form {
  width: 370px;
  margin: 0 auto;
}

.ClientPanel .ant-row {
  height: auto;
  min-height: auto;
  margin: 0 0 25px;
  display: flex;
  .ant-form-item {
    margin-bottom: 0;
  }
}

.ClientPanel .ant-form-item-label {
  text-align: left;
  min-width: 110px;
  // margin-right: 65px;
  height: 35px;
  align-items: center;
  display: flex;
  label {
    font: normal normal bold 14px/18px Lato;

    color: #707070;
    &::after {
      content: none;
    }
  }
}

.ClientPanel .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.ClientPanel .ant-form-item-control {
  input {
    display: block;
    text-overflow: ellipsis;
  }
  .ant-form-item-explain {
    // position: absolute;
    margin-bottom: 0;
  }
}

.ClientPanel .ant-form-item-control-input .ant-select-selector {
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 20px;
  .ant-select-selection-search {
    left: 0;
  }
  .ant-select-selection-item {
    font-weight: bold;
    font-size: 14px;
  }
}

.ClientPanel .ant-select-selection-search input {
  height: 35px !important;
  padding-left: 20px !important;
}

@primary-color: #1DA57A;