.VatTableButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.VatTableButtons .buttonSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 5px;
  background-color: aliceblue;
  padding: 0 5px;
}

.VatTableButtons .buttonSection h4 {
  margin: 0;
}

.VatTableButtons .client-action-button.ant-btn[disabled],
.VatTableButtons .client-cancel-button.ant-btn[disabled] {
  padding: 3px 11px;
}

.VatTableButtons .popovers {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 5px;
  font-size: 15px;
}

.VatTableButtons .popover-icon:hover {
  cursor: pointer;
  background-color: #1da57a;
  color: #daffe8;
}

.VatTableButtons .icon-button,
.VatTableButtons .ant-btn[disabled] {
  border: none;
  padding: 0;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VatTableButtons .icon-button {
  background: none;
  border: none;
}

.VatTableButtons .icon-group input[type="file"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.VatTableButtons .icon-group label {
  max-height: 14px;
}
@primary-color: #1DA57A;