.ReactTable .rt-tbody .rt-tr {
  user-select: none;
}

.table-wrapper {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  height: calc(100% - 30px);
}

.ClientPanel {
  position: relative;
}

.table-details {
  width: 100%;
  padding: 30px 50px;
  box-sizing: border-box;
  background: #fff;
  height: calc(100% - 70px);
  border-radius: 5px;
  box-shadow: 0px 0px 35px #4b65841f;
  position: relative;
  top: 40px;
  overflow-y: auto;
  h2 {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 35px;
  }

  label {
    font-size: 14px;
    color: #707070;
    font-weight: bold;
  }
  button {
    height: 40px;
    color: #fff;
    font-weight: bold;
    max-width: 150px;
    margin: 0 auto;
    cursor: pointer;
    background-color: #4b6584;
  }
}

.input-wrapper {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
  label {
    min-width: 150px;
    display: inline-block;
  }

  input {
    display: inline-block;
    text-overflow: ellipsis;
    background: #fff;
    color: #233b53;
    font-weight: bold;
    font-size: 14px;
    border: none;
    padding: 3px 4px;
    box-sizing: border-box;
    width: 100%;
  }
}

.single-entity-edit {
  width: calc(100% - 200px);
  margin: 20px auto;
  padding: 10px 20px;
  .ant-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
      max-height: 36px;
    }
    .ant-form-item-explain {
      white-space: nowrap;
    }
  }

  .form-section {
    &:first-of-type {
      width: 55%;
    }

    width: calc(45% - 50px);
    h2 {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 14px;
    }
    .ant-select-selection-item {
      min-width: 220px;
    }
    .ant-row.ant-form-item {
      & > div {
        // max-width: 220px;
      }

      .ant-form-item-control {
        width: fit-content;
        max-width: fit-content;
        min-width: 150px;
      }

      // .ant-select-selection-item {
      //   display: block;
      //   text-overflow: ellipsis;
      // }
      .ant-select-arrow {
        display: none;
      }
      .ant-picker {
        height: 36px;
        border-radius: 5px;
        padding-left: 0;
      }
      .ant-picker:hover,
      .ant-picker-focused {
        border-color: #233b53;
        box-shadow: none;
      }
      .ant-input[disabled] {
        background: #fff;
        border: none;
        color: #233b53;
      }
    }
  }

  .select-holder {
    display: flex;
    align-items: center;
    width: 55%;
    .fake-label {
      max-width: 33%;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: #707070;
    }
  }

  .cutom-select {
    max-width: 250px;
  }

  .form-section {
    margin-top: 40px;
  }
}

.rt-expandable {
  width: 25px !important;
  height: 25px;
}

.expandIcon {
  position: relative;
  top: -9px;
  right: -3px;

  img {
    width: 5px;
    transition: ease 0.3s;
  }

  &.open {
    img {
      transform: rotate(-90deg);
    }
  }

  &.close {
    img {
      transform: rotate(90deg);
    }
  }
}

.dropdown-select {
  width: 100%;
}

.dropdown-select .ant-select-selector {
  display: flex;
  align-items: center;
  height: 32px !important;
}

.ant-space {
  width: 100%;
}

.ant-space .ant-picker {
  width: 100%;
}

.ReactTable {
  width: 100%;
  position: static;
  border: none;
  color: #233b53;

  &.hidden {
    display: none;
  }

  .rt-thead {
    .rt-th {
      .dropdown-select {
        .ant-select-selector {
          .ant-select-selection-search {
            display: none;
          }
          .ant-select-selection-placeholder {
            opacity: 1;
            font-weight: bold;
            left: 20px;
          }
        }
      }
    }
    .rt-tr {
      border-bottom: 1px solid #233b53;
      // padding-bottom: 10px;
      .rt-th {
        box-shadow: none !important;
        &.-sort-asc {
          div {
            position: relative;
            &::after {
              content: "";
              right: -20px;
              position: absolute;
              background: url("../../images/sort.svg") center no-repeat;
              width: 5px;
              height: 10px;
            }
          }
        }
        &.-sort-desc {
          div {
            position: relative;
            &::after {
              content: "";
              right: -20px;
              position: absolute;
              background: url("../../images/sort.svg") center no-repeat;
              width: 5px;
              height: 10px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .rt-th {
      padding: 5px 0px;
      text-align: left;
      border: none;
      font: normal normal bold 14px/18px Lato;
    }
    &.-header {
      box-shadow: none;
    }
  }

  &.clean {
    .rt-thead {
      .rt-th {
        div {
          width: fit-content;
          &::after {
            top: 5px;
          }
        }
      }
    }
    .rt-tbody {
      .rt-td {
        padding: 10px 0px;
        border-right: none;
        margin-right: 10px !important;
        font: normal normal normal 14px/18px Lato;
        position: relative;
        &::after {
          position: absolute;
          top: 10px;
          right: 0px;
          height: 18px;
          width: 1px;
          background: #ddd;
          content: "";
        }
        &:last-of-type {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

.vatTable {
  max-height: 100%;
  padding-bottom: 60px;

  .rt-tr {
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 42px;
  }

  .rt-th {
    word-break: break-all;
    white-space: normal;
  }

  .rt-tbody {
    overflow: hidden;
  }

  .rt-tbody .rt-tr-group {
    background-color: white;
    max-height: 42px;
  }

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    padding: 4px 5px !important;
  }

  .rt-thead {
    padding: 30px 40px 0px 35px;
    .rt-tr {
      .rt-th {
        display: flex;
        align-items: flex-end;

        font-size: 12px;
        height: 40px;
        // padding: 0;
        padding: 7px 5px;
        &:first-of-type {
          display: none;
        }
      }
    }
  }

  .rt-tbody {
    overflow: visible;
    padding: 0 40px 0 35px;
    .rt-tr {
      .rt-td {
        border: none;
        // padding: 0;
        font-size: 12px;
        text-overflow: ellipsis;
        display: block;
        align-items: center;

        &:first-of-type {
          position: absolute;
          left: -20px;
        }
        &:nth-of-type(2) {
          padding: 11px 8px;
          cursor: pointer;
        }
      }

      &.blocked {
        .rt-td {
          color: #bebebe !important;
        }
      }
    }
  }
  .rt-tr-group {
    border-bottom: 1px solid #dddddd !important;

    &:first-of-type {
      .vatRow {
        &::after {
          content: none !important;
        }
      }
    }
  }
  &.vatDetailsTable {
    .rt-thead {
      .rt-tr {
        .rt-th {
          align-items: center;
        }
      }
    }
    padding-top: 30px;
    &.detailVisible {
      max-width: calc(60% - 70px);

      .rt-thead,
      .rt-tbody {
        padding-right: 0;
      }
    }
    .rt-thead {
      padding-top: 0;
    }
    .rt-tbody {
      .rt-tr {
        height: 41px;
      }

      .rt-td {
        &:first-of-type {
          display: none;
        }
      }
      .rt-tr-group {
        cursor: pointer;
      }
    }
  }
  .ant-select-selector .ant-select-selection-item {
    font-size: 12px;
    max-height: 80%;
    margin: 0;
    margin-right: 5px;
  }
}

.vatTable_records {
  .rt-tbody .rt-tr-group {
    background-color: white;
    max-height: initial;

    .rt-td {
      &:nth-last-child(4) {
        text-align: end;
      }
      &:nth-last-child(2) {
        text-align: end;
      }
    }
  }
}

.details-table-wrapper {
  display: flex;
  height: 100%;
}

.vatRow {
  position: relative;
  width: 100%;
  border-top: 1px solid #dddddd;

  .correction-arrow {
    width: 18px;
    height: 40px;
    position: absolute;
    z-index: 1000; //https://ant.design/components/modal/ -> The z-index of the Modal number 1000 (na przykładzie VATUEKModalContent) ustawiam na taką liczbę aby nie nachodziło na modal
    transform: rotate(90deg);
    left: -5px;
    img {
      position: absolute;
      top: 13px;
      left: 6px;
      display: none;
    }

    img.show {
      display: block;
    }
  }

  &:nth-of-type(2) {
    &::before {
      content: " ";
      width: 100%;
      height: 1px;
      background-color: #707070;
      position: absolute;
      top: -44px;
    }
  }

  &:last-of-type {
    &::after {
      content: " ";
      width: 100%;
      height: 1px;
      background-color: #707070;
      position: absolute;
      top: 42px;
    }
  }

  .rt-table {
    overflow: hidden;
  }

  .rt-tbody {
    min-width: 1035px;
    border-top: none;
    padding: 0;

    .rt-tr-group {
      border-bottom: none !important;
    }

    .rt-td {
      // padding: 0px 0px 0 25px !important;
      // width: 100px;

      &:first-of-type {
        position: initial !important;
        cursor: pointer;
      }

      &.date,
      &.status {
        max-width: 100px;
      }
      &.custom-action-icons {
        padding: 0 !important;
        width: 110px !important;
        max-width: 110px;
      }

      position: relative;
      left: 10px;

      // &:last-of-type {
      //   position: relative;
      //   left: -20px;
      //   padding: 0 13px 0px !important;
      // }
    }
  }

  .table-action-buttons button {
    padding: 10px 20px 10px 0 !important;
  }
}

.vatRow_selected {
  .rt-tr-group {
    .rt-tr {
      background-color: #f4f4f4;
    }
  }
}

.pagination-bottom {
  position: absolute;
  width: calc(100% - 0px);
  box-sizing: border-box;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-width: 500px;
  height: 40px;
  background: #fff;

  .page-size {
    position: absolute;
    right: 90px;
    height: 30px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    display: flex;
    padding: 0 5px;
    visibility: hidden; //tymczasowo ukrywamy możliwość wyboru ilości rekordów na stronę póki nie dopracujemy zachowania tabel

    select {
      border: none;
      outline: none;
      background: none;
      margin-left: 5px;
      min-width: 35px;
      -moz-appearance: none !important;
      background: transparent url("../../images/chevron.svg") right center no-repeat !important;
      background-position: calc(100% - 3px) 12px !important;
    }

    span {
      position: absolute;
      left: -100%;
      height: 30px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #233b53;
    }
  }

  .Table {
    &__pagination {
      display: flex;
    }

    &__visiblePagesWrapper {
      margin: 0 10px;
    }

    &__pageButton {
      border: none;
      background: none;
      font: normal normal normal 14px/18px Lato;
      color: #233b53;
      padding: 1px 8px;
      position: relative;
      outline: none;
      cursor: pointer;
      &--active {
        font-weight: bold;
        text-decoration: underline;
      }

      &:disabled {
        opacity: 0.2;
        cursor: initial;
      }
    }
  }

  .three-dots {
    color: #dddddd;
    cursor: initial;
    margin-right: 12px;
  }

  .nav-icon {
    background: none;
    border: none;
    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.react-date {
  text-decoration: underline;
  color: #0fb9b1;
}

.table-action-section {
  height: 60px;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  box-shadow: 0px 0px 12px #e8e8e8;
  background: #fff;
  border-radius: 5px;
  justify-content: space-between;
  .ant-input-search {
    margin-left: 40px;
  }

  @media (min-width: 1920px) {
    height: 70px;
    margin-bottom: 30px;
  }
}

.action-section-buttons {
  display: flex;
  align-items: center;
  padding-right: 50px;
  button {
    margin-top: 5px;
    input {
      display: none;
      &:disabled {
        & + label {
          opacity: 0.2;
        }
      }
    }
    label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: #233b53;
    align-items: center;
    margin-right: 20px;
    outline: none;
    img {
      will-change: transform;
      transition: transform 0.1s ease-in-out;
      margin-bottom: 2px;
      max-height: 25px;
    }
    &:disabled {
      opacity: 0.2;
      cursor: initial;
    }
    &:hover {
      &:not(:disabled) {
        img {
          transform: translateY(2px);
        }
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.dash {
  width: 1px;
  height: 25px;
  background: #dddddd;
  margin-right: 20px;
}

.table-action-buttons {
  display: flex;
  align-items: center;
  button {
    outline: none;
    border: none;
    cursor: pointer;
    background: inherit !important;
    padding: 0 20px 0 0;
    &:disabled {
      opacity: 0.2;
      cursor: initial;
    }
    img {
      min-height: 17px;
    }
    &:last-of-type {
      padding: 0 10px 0 0;
    }
  }
}

.FormModal.exportModal {
  .ant-modal-content {
    height: auto;
    width: auto;
    color: #233b53;
  }

  .ant-modal-body {
    align-items: flex-start;
    padding: 50px 90px;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    color: #233b53;
    margin-bottom: 35px;
  }

  &.researchModal {
    h3 {
      margin-bottom: 15px;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons {
    margin-top: 50px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    button span {
      text-transform: initial;
    }
  }
  .details-item {
    height: auto;
    min-height: auto;
    margin-bottom: 10px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .label {
    font-weight: bold;
    font-size: 14px;
    color: #233b53;
  }
}

// aprove del modal style
.delModal {
  &.demoModal {
    .ant-modal-content {
      height: auto;
    }
  }

  p {
    text-align: center;
    color: #233b53;
  }

  button {
    width: 139px;
    height: 36px;

    span {
      text-transform: inherit;
    }
  }
}

.ReactTable .rt-td {
  padding: 11px 5px !important;
}

.ant-select-selection-search {
  input {
    left: -10px;
    position: relative;
  }
}

.filler-center {
  width: 100%;
  display: flex;
  align-self: center;
  margin: 150px auto 0;
  flex-direction: column;
  max-width: 50%;
  span {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }
}

.ant-select-selection-item {
  color: #233b53;
}

.broken-search {
  .ant-input-wrapper.ant-input-group {
    top: 0;
    position: absolute;
    height: 100%;
    left: 0;
    border: none;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border: 0;
    height: 100%;
    &:focus,
    &:hover {
      border: none;
    }
  }

  .ant-input-affix-wrapper {
    border: 1px solid #dedede;
    border-radius: 5px !important;
    &:focus,
    &:hover {
      border: 1px solid #dedede;
    }
  }
  input {
    max-width: calc(100% - 30px);
  }
  .ant-input-group-addon {
    display: none;
  }
}

.fake-button {
  margin-top: 5px;
  &.disabled {
    opacity: 0.2;
  }
  input {
    display: none;
    &:disabled {
      & + label {
        opacity: 0.2;
      }
    }
  }
  label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  color: #233b53;
  align-items: center;
  margin-right: 20px;
  outline: none;
  img {
    will-change: transform;
    transition: transform 0.1s ease-in-out;
    margin-bottom: 2px;
    max-height: 25px;
  }
  &:disabled {
    opacity: 0.2;
    cursor: initial;
  }
  &:hover {
    &:not(:disabled) {
      img {
        transform: translateY(2px);
      }
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.addNewUserModal {
  width: 600px !important;
  h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 0 !important;
  }
  .ant-modal-body {
    padding: 45px 50px 45px 65px !important;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-form-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .ant-col {
    max-width: 340px;
  }

  label {
    min-width: 100px;
    color: #707070;
    min-height: 38px;
    max-height: 38px;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
  }

  .buttons {
    margin-top: 40px !important;
  }

  .ant-form-item-explain-error {
    position: absolute;
    bottom: -30px;
  }
}

.add-new-user-form {
  .ant-select-clear {
    right: 12px !important;
  }
  .ant-row {
    min-height: 38px;
    max-height: 38px;
    input {
      height: 38px;
    }
  }

  .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback > .ant-select .ant-select-clear,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
    right: 7px !important;
  }
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #dddddd !important;
}

.vatTable_records {
  .rt-thead {
    .rt-tr {
      .rt-th {
        &:nth-last-child(-n + 6) {
          justify-content: flex-end;
        }
        &:nth-last-child(2) {
          justify-content: center;
        }
      }
    }
  }

  .rt-tbody {
    .rt-tr {
      .rt-td {
        flex: 100 0 auto;
        width: 100px;

        &:nth-last-child(-n + 6) {
          text-align: end;
          display: flex;
          justify-content: flex-end;
        }
        // &:nth-last-child(2) {
        //   justify-content: center;
        //   text-align: center;
        // }
      }
    }
  }
}

.status-icons-position {
  justify-content: center !important;
}

.wlTable_records {
  .rt-thead {
    .rt-tr {
      .rt-th {
        justify-content: flex-start;
        align-items: center;
      }
      .rt-th:nth-child(8) {
        justify-content: flex-start;
        align-items: center;
        &.-sort-asc {
          div {
            &::after {
              margin-top: 7px;
            }
          }
        }
        &.-sort-desc {
          div {
            &::after {
              margin-top: 7px;
            }
          }
        }
      }
    }
  }
  .rt-tbody {
    .rt-tr {
      .rt-td {
        text-align: start;
      }
    }
  }
}

.status-header {
  display: flex;
  margin: 0 auto;
}

@primary-color: #1DA57A;