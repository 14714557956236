.action-section {
  text-align: center;
  min-width: 180px;
  width: 100%;

  & > span {
    text-transform: uppercase;
    color: #20b9b1;
  }

  .icon-box {
    max-width: 80px;
  }

  .buttons {
    display: flex;
    padding-top: 20px;
    justify-content: space-around;
    height: 60px;

    &.separator {
      border-right: 1px solid #dedede;
    }
  }
}

@primary-color: #1DA57A;