// ANT DESIGN STYLES
@import "~antd/dist/antd.compact.less";

// FONTS
// Lato light
@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: local("Lato"), url(./fonts/Lato-Light.ttf) format("truetype");
}

// Lato regular
@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: local("Lato"), url(./fonts/Lato-Regular.ttf) format("truetype");
}

// Lato bold
@font-face {
  font-family: "Lato";
  font-weight: 700;
  src: local("Lato"), url(./fonts/Lato-Bold.ttf) format("truetype");
}

// Lato black
@font-face {
  font-family: "Lato";
  font-weight: 900;
  src: local("Lato"), url(./fonts/Lato-Black.ttf) format("truetype");
}

.cookie-modal.FormModal {
  max-width: 980px;
  .ant-modal-content {
    width: 100%;
  }

  .cookie-body {
    h2 {
      &:first-of-type {
        text-transform: uppercase;
        text-align: center;
      }
      font: normal normal bold 14px/18px Lato;
      letter-spacing: 0px;
      color: #233b53;
      &.custom {
        display: flex;
        justify-content: space-between;
        label {
          position: relative;
          right: 210px;
          font-size: 14px;
          color: #233b53;
        }
      }
    }

    width: 100%;
    p {
      margin: 30px 0 45px;
      color: #233b53;
      font-weight: unset;
    }
  }
  .buttons {
    button {
      min-width: 160px;

      span {
        text-transform: initial;
      }
    }
  }
}

@primary-color: #1DA57A;