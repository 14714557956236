.wrapper {
  display: flex;
  width: 100%;
  padding: 0px 100px;
  box-sizing: border-box;
}

.form {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  max-width: 650px;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  top: -30px;
}

.form-extended {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  max-width: calc(100% - 300px);
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  top: -30px;
}

h3 {
  font-weight: bold;
  margin-bottom: 30px;
}

.section {
  max-width: calc(100% / 2 - 55px);
  min-width: 250px;
  width: 100%;
}

.section-extended {
  max-width: calc(100% / 3 - 55px);
  min-width: 250px;
  width: 100%;
}

.agree {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  min-width: 720px;
  .ant-row {
    min-height: 50px;
  }
  .ant-checkbox-wrapper p {
    top: 0;
  }
  .ant-form-item-control-input .ant-checkbox-wrapper p {
    top: 0px;
    font-size: 12px;
  }
  .ant-form-item-control-input {
    height: 30px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    margin: 0px 0;
  }
}

.login-button-container {
  margin: 0 auto;
  min-width: 200px;
}

.ant-checkbox-wrapper {
  display: flex;

  .ant-checkbox::after {
    display: none;
  }

  .ant-checkbox,
  .ant-checkbox-input,
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner,
  .ant-select-selector {
    border-radius: 5px;
  }

  &:hover,
  &:focus,
  &:active {
    .ant-checkbox-inner {
      border-color: #233b53;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;
      border-color: #233b53;

      &:after {
        border-color: #233b53;
        width: 6px;
        height: 10px;
      }
    }
  }

  p {
    position: relative;
    top: -3px;
    left: 3px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;

    span,
    a {
      color: #2a9cb3;
    }
  }
}

@primary-color: #1DA57A;