.VatDetails-navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 12px #e8e8e8;
  border-radius: 5px;
  height: 60px;
  margin-bottom: 10px;
  padding: 5px 35px 5px 43px;

  .left-panel {
    display: flex;
    align-items: center;

    .arrow-wrap {
      margin-right: 20px;
      cursor: pointer;
    }

    .ant-input-affix-wrapper-disabled {
      background-color: #ffffff;
    }

    h3 {
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 20px 0 0;
      width: 276px;
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix {
        position: relative;
        right: -30px;
      }
    }
  }

  .right-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-box{
      width: 55px;
    }

    .icon-box:nth-of-type(4){
      width: 70px;
    }

    .icon-box:nth-of-type(4) {
      &::after {
        content: " ";
        top: 9px;
        height: 23px;
        width: 1px;
        background-color: #dddddd;
        position: absolute;
        right: 0px;
      }
    }
  }
}

.WlDetails-navbar{
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 12px #e8e8e8;
  border-radius: 5px;
  height: 60px;
  margin-bottom: 10px;
  padding: 5px 35px 5px 43px;

  .left-panel {
    display: flex;
    align-items: center;

    .arrow-wrap {
      margin-right: 20px;
      cursor: pointer;
    }

    .ant-input-affix-wrapper-disabled {
      background-color: #ffffff;
    }

    h3 {
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 20px 0 0;
      width: 276px;
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix {
        position: relative;
        right: -30px;
      }
    }
  }

  .right-panel {
    display: flex;
    width: 343px;
    justify-content: space-between;
    align-items: center;

    .icon-box:nth-of-type(4) {
      &::after {
        content: " ";
        top: 9px;
        height: 23px;
        width: 1px;
        background-color: #dddddd;
        position: absolute;
        right: -11px;
      }
    }
  }
}

.ant-input-search {
  position: relative;
  border-radius: 5px;
  padding: 0 0 0 10px;

  input {
    height: 100%;
  }

  .ant-input-search-icon::before {
    content: none;
  }

  .ant-input-suffix {
    width: 44px;

    span > {
      opacity: 0;
    }
  }
}

.ant-input-affix-wrapper {
  input {
    height: auto;
  }

  border-radius: 5px;
  position: relative;
  &:not(.ant-input-password) {
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      right: 8px;
      background-image: url(../../../images/icons/search_icon.svg);
      background-repeat: no-repeat;
      background-position: center center;
      width: 20px;
      height: 20px;
    }
  }

  // span > {
  //   opacity: 0;
  // }
}

.search-input {
  .ant-input-suffix {
    span {
      &:first-of-type {
        opacity: 1;
        position: relative;
        right: -50px;
      }
    }
  }
}

@primary-color: #1DA57A;