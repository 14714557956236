.jobs-filters-panel {

  .ant-input-search .ant-input-suffix span {
    opacity: 1;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 35px;

    .filter-field {
      width: 23%;
      border-radius: 5px;
    }
  }

  .flex-container .filter-header {
    padding: 0px 0px 5px 3px;
    width: 23%;
  }


  .filters-search {
    .ant-input-wrapper.ant-input-group {
      top: 0;
      position: absolute;
      height: 100%;
      left: 0;
      border: none;
    }

    .ant-input,
    .ant-input-affix-wrapper {
      border: 0;
      height: 100%;

      &:focus,
      &:hover {
        border: none;
      }
    }

    .ant-input-affix-wrapper {
      border: 1px solid #dedede;
      border-radius: 5px !important;

      &:focus,
      &:hover {
        border: 1px solid #dedede;
      }
    }

    input {
      max-width: calc(100% - 30px);
    }

    .ant-input-group-addon {
      display: none;
    }
  }

  .filtersRow .ant-btn .ant-dropdown-trigger .filter-dropdown {
    width: "55%";
    justify-content: "space-between";
    align-items: "center";
    color: "#233B53";
    border-color: "#DDDDDD";
    height: "38px";
    padding-right: "1%";
    padding-left: "35px"
  }
}


.JobsHistoryRangePicker {

  // single panel
  .ant-picker-panels>*:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels>*:last-child {
    display: none;
  }

  .ant-picker-footer-extra>div {
    flex-wrap: wrap !important;
  }

  .ant-picker-header-view {
    pointer-events: none;
  }

  // end single panel

  .ant-picker-input {
    height: 18px;
    width: 50px;

    input {
      text-align: center;
    }
  }

  .picker-time-range-row {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #233B53;
  }

  .ant-btn ant-btn ant-btn-primary ant-btn-sm {
    span {
      background: #233B53;
    }
  }
}

@primary-color: #1890ff;
@primary-color: #1DA57A;