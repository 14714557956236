.gate-action-buttons {
  height: 100%;
  padding: 16px 0;
  box-sizing: border-box;

  width: 65%;
  justify-content: flex-end;
  display: flex;
}

@primary-color: #1DA57A;