.Modal-wrapper {
  background-color: #ffffff;
  padding: 28px 40px;
  width: 495px;

  &:before,
  &:after {
    content: "";
    height: 20px;
    width: 20px;
    background: url(../../../images/icons/four_dots_green.svg) 0 0 no-repeat;
    background-size: cover;
    position: absolute;
  }

  &:before {
    top: 10px;
    left: 10px;
  }

  &:after {
    bottom: 10px;
    right: 10px;
    transform: rotate(180deg);
  }
}

@primary-color: #1DA57A;