// Styles for Modal

.Modal-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: #ffffff96;
}

.Modal-box .Modal-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 460px;
  box-shadow: 0px 0px 35px #4b65841f;
  padding: 7px;
  background: #ffffff;
  border-radius: 5px;
}

.Modal-box .Modal-content {
  padding: 30px;
  border: 3px solid #ff0000;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  font: normal normal normal 14px/21px Lato;

  h3 {
    color: #ff0000;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    color: #233b53;
    font-weight: bold;
  }

  p:last-of-type {
    margin-bottom: 20px;
  }

  ul {
    list-style: decimal;
    width: 500px;
    height: 218px;
    border: 1px solid #bebebe;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 15px 30px;
    margin-bottom: 26px;

    li {
      margin-bottom: 30px;
    }

    li:last-of-type {
      margin-bottom: 0;
    }
  }

  .buttons-box {
    display: flex;
    max-width: 49%;
    margin: 0 auto;
    justify-content: space-between;
    button {
      height: 36px;
      width: 109px;
    }
  }
}

// Styles for Notification Trigger

.Trigger-wrapper {
  width: 300px;
}

.Trigger-box {
  border: 3px solid;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 60px;
  box-shadow: 0px 0px 35px #4b65841f;
  font: normal normal bold 12px/18px Lato;
  color: #233b53;
  display: flex;
  position: relative;

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  .success-icon {
    position: absolute;
    bottom: 6px;
    right: 6px;
  }
}

.Trigger-box.warning {
  border-color: orange;
}

.Trigger-box.success {
  border-color: #00a74f;
}

.Trigger-box.error {
  border-color: #ff0000;
}

.Trigger-box.vat {
  border-color: #0FB9B1;
}

.NonEuNip{
  margin-right: 50px;
}
@primary-color: #1DA57A;