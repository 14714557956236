.User {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.User .image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 230px;
  margin-bottom: 30px;
}
.image-container-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;
  z-index: 999;
  width: 210px;
  height: 210px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 30px;
  border-radius: 100%;
  left: 0;
  right: 0;
  font: 74px / 89px Lato;
  letter-spacing: 0px;
  color: #233b53;
  box-shadow: 0px 0px 24px #233b531f;
  position: relative;
  overflow: hidden;
}

.User .header button.ant-btn {
  border: none;
  box-shadow: none;
  padding: 0 5px;
  margin-right: 40px;
  span {
    text-transform: none;
    font-size: 14px;
  }
}

.User .columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}

.User .columns div {
  padding: 5px;
}

.User .edit-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.User .edit-buttons .login-button-container {
  margin: 0;
}

.User .edit-form {
  min-width: 400px;
}

.User .edit-buttons .login-button-container:nth-of-type(1) {
  margin-right: 20px;
}

.User .notification {
  border-radius: 10px;
  padding: 30px 35px;
  width: 340px;
  right: 30px;
  top: 80px;
  position: absolute;
  background: #fff;
  z-index: 99999;
  box-shadow: 0px 0px 35px #4b65841f;
  &::before {
    content: "";
    border: 3px solid green;
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 5px;
    top: 0;
    left: 0;
    margin: 5px;
    opacity: 0.25;
  }
}

.User .notification h3 {
  margin: 0;
  font: normal normal bold 14px/21px Lato;
  letter-spacing: 0px;
  color: #233b53;
}

.User .notification .close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.user.edit-form {
  .ant-row {
    justify-content: center;
    max-width: 230px;
    width: 100%;
    margin: 0 auto 25px;
  }
  .ant-form-item-control {
    min-width: 100%;
  }
}

.User .image-container .image-button {
  visibility: hidden;
}

.action-icon {
  cursor: pointer;
}

.basic-info {
  h3 {
    text-align: center;
    font: normal normal bold 18px/24px Lato;
    letter-spacing: 0px;
    color: #233b53;
    text-transform: uppercase;
  }
}

.additional-info {
  margin-top: 30px;
  h3 {
    text-align: center;
    font: normal normal 300 18px/22px Lato;
    letter-spacing: 0px;
    color: #233b53;
    text-transform: lowercase;
  }
}

// .user-navigation {
//   position: absolute;
//   top: 0;
//   right: 30px;
//   top: 30px;
// }

.avatar-edit {
  position: absolute;
  bottom: 0;
  right: 0;
}

.avatar-delete {
  position: absolute;
  bottom: 0;
  left: 0;
}

@primary-color: #1DA57A;