.icon-box {
  display: flex;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
  position: relative;
  border: none;
  background-color: #ffffff;
  padding: 0;
  outline: none;

  img {
    margin-bottom: 4px;
    transition: transform 0.1s ease-in-out;
  }

  p {
    color: #233b53;
    font-size: 10px;
    line-height: 13px;
  }

  &:disabled {
    cursor: inherit;

    opacity: 0.3;
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      img {
        transform: translateY(2px);
      }
    }
  }
}

@primary-color: #1DA57A;