.VatDetails .Declarations {
  display: flex;
  margin-top: 12px;
  padding: 0px 0 0px 75px;

  .ant-tabs-tabpane {
    min-height: 650px;
  }

  .Declarations-header {
    width: 100%;

    .ant-tabs-nav {
      margin: 0;

      .ant-tabs-tab {
        width: 220px;

        .ant-tabs-tab-btn {
          font: normal normal bold 12px/16px Lato;
          text-transform: uppercase;
        }
      }

      .ant-tabs-ink-bar {
        background-color: #233b53;
      }
    }
  }

  .Tax-col {
    position: relative;
    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
      color: #233b53;
      text-align: left;
    }

    &.Tax-col_one {
      flex-basis: 400px;
      padding-right: 50px;
    }

    &.Tax-col_two {
      flex-basis: 300px;
      display: flex;
      align-items: center;
    }

    &.Tax-col_third {
      flex: 1;
      display: flex;
      align-items: center;

      @media only screen and (min-width: 1367px) {
        flex: initial;
        flex-basis: 495px;
      }
    }
  }

  .Tax-header {
    width: 100%;
    display: flex;
    margin-top: 25px;

    .Tax-col {
      &.Tax-col_two {
        padding-left: 110px;
        padding-right: 60px;
      }

      &.Tax-col_third {
        padding-left: 115px;
        padding-top: 18px;
      }
    }

    h4 {
      font: normal normal bold 12px/16px Lato;
      text-transform: uppercase;
    }
  }

  .Taxdue,
  .Taxinput,
  .Taxcalc,
  .Taxadd {
    .Tax-content {
      position: relative;
      margin-top: 20px;

      .ant-form-item {
        &.radio-buttons {
          width: 100%;
          .ant-form-item-explain {
            bottom: 5px;
            left: 0px;
            position: relative;
            margin-bottom: 10px;
          }
        }
        &.textarea {
          min-width: 250px;
          .ant-form-item-explain {
            bottom: -3px;
            left: 0px;
            position: relative;
            margin-bottom: 10px;
          }
        }
      }
      .content-row {
        display: flex;
        margin-bottom: 30px;

        &:nth-of-type(3),
        &:nth-of-type(5) {
          .Tax-col_one {
            padding-left: 20px;
          }
        }

        &:nth-last-child(2) {
          margin-bottom: 45px;
          &.radio {
            margin-bottom: 20px;
          }
        }

        &:nth-last-child(1) {
          margin-bottom: 50px;
          &.radio {
            margin-bottom: 20px;
          }
        }

        .const-value {
          font: normal normal normal 12px/16px Lato;
          color: #bebebe;
          width: 27%;
          text-align: right;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .value {
          font: normal normal normal 12px/16px Lato;
          color: #233b53;
          min-width: 29%;
          padding: 0 10px;
          text-align: center;
          position: relative;

          .ant-checkbox {
            position: relative;
            left: 10px;
          }
        }

        .Tax-col_third {
          .const-value {
            width: 17%;
          }

          .value {
            min-width: 18%;
          }
        }
      }

      .info {
        z-index: 1;
        img {
          cursor: pointer;
        }
      }

      .custom-alert {
        position: absolute;
        width: 130px;
        color: #ff4d4f;
      }

      .instruction-box {
        width: 420px;
        display: flex;
        margin-top: 30px;

        p {
          font: normal normal bold 12px/16px Lato;
          padding-right: 35px;
        }

        .instruction-item {
          display: flex;
          position: relative;

          .ant-row {
            width: 30px;
          }

          .label-error {
            .ant-checkbox-inner {
              border-color: #ff4d4f;
            }
          }

          .info {
            position: relative;
            top: 3px;
            margin-left: 5px;
          }

          .custom-alert {
            top: 26px;
            right: -86px;
          }
        }

        @media only screen and (min-width: 1380px) {
          position: absolute;
          top: -10px;
          left: 700px;
          margin-top: 0;
        }
      }
    }
  }

  .Taxdue,
  .Taxinput {
    .content-row {
      &:last-of-type {
        .sum-alert {
          position: absolute;
          bottom: -20px;
        }
      }
    }
  }

  .Taxinput {
    .Tax-header {
      .Tax-col {
        &.Tax-col_two {
          padding-top: 18px;
        }
      }
    }

    .content-row {
      .Tax-col_one {
        padding-left: 0 !important;
      }
    }
  }

  .Taxcalc {
    .Tax-header {
      margin-bottom: 20px;
    }

    .Tax-content {
      margin-top: 7px;

      .calc-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .Tax-col {
          display: flex;
        }

        .Calc-col_one {
          flex-basis: 430px;
        }

        .Calc-col_two {
          flex: 1;
          max-width: 200px;
          justify-content: flex-end;
          padding-right: 7px;

          .value {
            width: 50%;
            text-align: right;
            padding-right: 30px;
          }

          .value_number {
            text-align: right;
            margin-right: 30px;
          }

          .value_checkbox {
            .ant-row {
              width: initial;

              .ant-checkbox {
                top: -3px;
              }
            }
          }

          .value_radio__error {
            position: relative;
            display: flex;
            flex-flow: column;
            align-items: flex-end;

            .ant-radio-inner {
              border-color: #ff4d4f;
            }

            .radio-error-info {
              color: #ff4d4f;
              min-width: 103px;
            }
          }

          .custom-number-error {
            position: relative;
            display: flex;
            flex-flow: column;
            align-items: flex-end;

            .ant-input-number {
              border-color: #ff4d4f;
            }

            .custom-alert {
              position: relative;
              left: 111px;
              width: 220px;
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              bottom: -5px;
            }

            .sum-alert-icon {
              position: absolute;
              right: -17px;
              width: 17px;
              top: 3px;
            }

            .ant-form-item-explain {
              display: none;
            }
          }
        }
      }

      .calc-row__inputs {
        border: none !important;

        &:nth-of-type(3) {
          margin-bottom: 31px;
        }

        &:nth-of-type(14) {
          .Calc-col_two {
            max-width: initial;
            justify-content: flex-start;
            margin-right: 30px;

            .textarea-box {
              padding-left: 68px;
              max-width: 668px;
              width: 100%;
              position: relative;
              margin-right: 6px;
            }

            .textarea-box__error {
              textarea {
                border-color: #ff4d4f;
              }

              .textarea-error-info {
                color: #ff4d4f;
                position: relative;
                top: 2px;
              }
            }

            .ant-row {
              width: 100%;
            }

            .ant-form-item-explain {
              left: 0;
            }
          }

          textarea {
            height: 90px;
          }
        }

        &:last-child {
          margin-bottom: 50px;
        }

        .ant-form-item-explain {
          position: relative;
          min-width: 205px;
          bottom: -5px;
        }

        .ant-form-item-children-icon {
          top: 12px;
        }
      }

      .calc-row__radio {
        margin-bottom: 16px;

        &:nth-of-type(11) {
          margin-bottom: 22px;
        }

        &:nth-of-type(12) {
          margin-bottom: 20px;
        }

        .ant-radio {
          top: 3px;

          .ant-radio-inner {
            width: 17px;
            height: 17px;
            border-color: #233b53;

            &:after {
              top: 4px;
              left: 4px;
              width: 7px;
              height: 7px;
              background-color: #233b53;
            }
          }
        }
      }
    }

    .Calc-summary {
      margin-bottom: 60px;
    }
  }

  .Taxadd {
    .Tax-header {
      h4 {
        text-transform: none;
      }
    }

    .Tax-content {
      margin-top: 10px;

      .add-row {
        display: flex;
        align-items: center;
        margin-bottom: 17px;

        &:nth-of-type(5),
        &:nth-of-type(6) {
          margin-bottom: 25px;
        }

        &:nth-last-of-type(2) {
          margin-bottom: 0;
          margin-top: 25px;

          .Tax-col {
            align-items: flex-start;
          }

          .Tax-col_two {
            max-width: none;
            justify-content: flex-start;
            margin-right: 20px;

            .value {
              max-width: 450px;
              min-width: 148px;
              width: initial;
              margin-right: 20px;
              padding-left: 50px;
            }
          }

          .textarea-box {
            padding-left: 60px;
            max-width: 668px;
            width: 100%;
            position: relative;
            margin-right: 15px;

            textarea {
              height: 80px;
            }

            textarea.error {
              border-color: #ff4d4f;
            }

            .info {
              padding-left: 13px;
            }

            .ant-row {
              width: 100%;
            }

            .custom-alert {
              bottom: -22px;
            }

            .ant-form-item-explain {
              bottom: -22px;
            }

            .ant-form-item-children-icon {
              top: 12px;
              right: -33px;
            }
          }
        }

        .Tax-col {
          display: flex;

          .value_checkbox {
            padding-left: 47px;
            padding-right: 45px;

            .ant-checkbox {
              position: relative;
              top: 1px;
            }

            .ant-row {
              width: initial;
            }
          }
        }

        .Tax-col_one {
          flex-basis: 430px;
        }

        .Tax-col_two {
          flex: 1;
          max-width: 185px;
          justify-content: flex-end;

          .const-value {
            width: 50%;
            text-align: right;
            margin-right: 30px;
          }

          .value {
            width: 50%;
          }

          .value_number {
            text-align: right;
            margin-right: 30px;
          }
        }

        .ant-form-item-explain {
          bottom: -19px;
          min-width: 470px;
          left: 0;
        }
      }
    }
  }

  .ant-form {
    .ant-row {
      min-height: initial;
      width: 123px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      margin-right: 6px;
      position: relative;

      .sum-alert {
        position: relative;
        bottom: -3px;
        left: 117px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #ff4d4f;
        min-width: 220px;
        text-align: left;
      }

      .sum-alert-icon {
        position: absolute;
        right: -23px;
        width: 17px;
        top: 3px;
      }
    }

    .ant-input-number {
      width: 103px;
      height: 28px;

      input {
        text-align: center;
        font: normal normal normal 12px/16px Lato;
        color: #233b53;
        &::placeholder {
          color: black;
          opacity: 1;
        }
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .ant-form-item-control {
      position: relative;

      .ant-form-item-children-icon {
        display: inline-block;
        right: -35px;

        .anticon-check-circle {
          display: none;
        }
      }

      .ant-form-item-explain {
        position: absolute;
        bottom: -18px;
        left: 20px;
        // min-width: 160px;
        min-width: 205px;
        text-align: left;
        margin: 0;
      }
    }

    .ant-form-item input[type="radio"],
    .ant-form-item input[type="checkbox"] {
      width: 17px;
      height: 17px;
    }

    .ant-checkbox-inner {
      width: 17px;
      height: 17px;
      border-color: #233b53;
      border-radius: 5px;

      &:after {
        left: calc(50% - 4px);
      }
    }
  }

  .custom-due-input {
    outline: none;
    text-align: center;
    font: normal normal normal 12px/16px Lato;
    color: #233b53;
    width: 103px;
    height: 28px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    transition: ease 0.3s;

    &:focus,
    &:active,
    &:hover {
      border: 1px solid black;
    }
  }

  .TaxNotification {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 347px;
    box-shadow: 0px 0px 35px #4b65841f;
    padding: 5px;
    background: white;
    z-index: 10000000;
    border-radius: 5px;
    cursor: pointer;

    .noti-inner {
      border: 3px solid rgba(91, 119, 152, 0.25);
      border-radius: 5px;
      padding: 29px 16px 24px 34px;
      position: relative;

      p {
        font: normal normal bold 14px/21px Lato;
      }

      .noti-img {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .custom-input__error {
    border: 1px solid #ff4d4f !important;
  }
}

textarea {
  resize: none;
}

.const-name {
  left: -20px;
  color: #0fb9b1;
  position: absolute;
  min-height: 28px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.VatDetails .Declarations .Calc-inputs .Tax-col.Tax-col_one {
  flex-basis: 450px;
}

.const-value {
  display: flex;
  align-items: center;
  font: normal normal normal 12px/16px Lato;
  color: #bebebe;
  text-align: right;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Calc-inputs {
  .ant-form-item-explain,
  .ant-form-item-explain-error {
    white-space: nowrap;
  }

  .const-value {
    min-width: 100px;
  }
}

.Taxcalc {
  .const-value {
    position: relative;
    right: -30px;
    height: 30px;
  }
}

.ant-radio-group {
  width: 100%;
}

@primary-color: #1DA57A;