.Expired {
  background-image: url(../../images/cloud.jpg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15%;
  font-size: 13px;
}

.Expired .paragraph {
  margin-bottom: 10px;
}

@primary-color: #1DA57A;