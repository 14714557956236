.Register {
  height: calc(100vh - 72px);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  max-width: 908px;
  margin: 0 auto;
  padding-top: 72px;

  @media (min-width: 1920px) {
    padding-top: 180px;
  }

  &:before,
  &:after {
    content: "";
    height: 105px;
    width: 65px;
    background: url(../../images/icons/register-dots.svg) 0 0 no-repeat;
    background-size: cover;
    position: absolute;
  }

  &:before {
    top: 50px;
    left: 50px;

    @media (min-width: 1920px) {
      top: 100px;
      left: 100px;
    }
  }

  &:after {
    bottom: 25px;
    right: 70px;
    transform: rotate(90deg);

    @media (min-width: 1920px) {
      bottom: 65px;
      right: 135px;
    }
  }

  .Register-return {
    width: 100%;
    margin-bottom: 58px;

    @media (min-width: 1920px) {
      margin-bottom: 105px;
    }

    a {
      color: #42b0c6;
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;

      img {
        margin-right: 14px;
        position: relative;
        top: -1px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .Register-header {
    display: flex;
    width: 100%;
    max-width: 600px;
    padding-left: 100px;
    margin-bottom: 60px;

    .header-box {
      display: flex;
      align-items: center;

      * > {
        font-weight: bold;
      }

      .elipse {
        font-size: 18px;
        line-height: 22px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 17px;
        border: 1px solid #233b53;
        border-radius: 100%;
      }

      .elipse.success {
        border: 1px solid #00a74f;

        img {
          width: 20px;
        }
      }

      h2 {
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin: 0;
      }

      &:last-of-type {
        padding-left: 60px;
      }

      &.blocked {
        opacity: 0.2;
      }
    }
  }

  .Register-form {
    width: 100%;
    position: relative;
    max-width: 100%;

    .Form-step.hidden {
      display: none;
    }

    .Step-info {
      color: #ff4d4f;
      position: absolute;
      bottom: -5px;
    }

    .ant-form {
      max-width: 230px;
      margin: 0 auto;
    }

    .ant-row {
      min-height: 60px;

      &.absolute {
        position: absolute;
        left: 0;
        min-height: 30px;
        height: 30px;
        &:last-of-type {
          bottom: -80px;
        }
        .ant-form-item-control-input .ant-checkbox-wrapper p {
          top: 0px;
        }
        .ant-form-item-control-input {
          height: 30px;
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin: 0;
        }
      }
    }

    .ant-form-item-control-input {
      height: 34px;

      .ant-input {
        height: 34px;
        padding: 0 15px;
        color: #233b53;
      }

      .ant-form-item-children-icon {
        right: -28px;
      }

      //  Register Box Password input style

      .ant-input-password {
        height: 34px;
        padding: 0;

        .ant-input {
          height: 32px;
        }

        .ant-input-suffix {
          position: relative;
          right: 10px;
        }
      }

      // Register Box Country Select style

      .ant-select-selector {
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding-left: 15px;
        font-size: 14px;
        color: #bebebe;
        caret-color: transparent;
        cursor: pointer;

        .ant-select-selection-item {
          color: #233b53;
          font-weight: bold;
        }
      }

      .ant-select-arrow {
        display: none;
      }

      //  Register Box Policy Checkbox style

      .ant-checkbox-wrapper {
        display: flex;

        .ant-checkbox::after {
          display: none;
        }

        .ant-checkbox,
        .ant-checkbox-input,
        .ant-checkbox-inner {
          width: 20px;
          height: 20px;
        }

        .ant-checkbox-inner,
        .ant-select-selector {
          border-radius: 5px;
        }

        &:hover,
        &:focus,
        &:active {
          .ant-checkbox-inner {
            border-color: #233b53;
          }
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #fff;
            border-color: #233b53;

            &:after {
              border-color: #233b53;
              width: 6px;
              height: 10px;
            }
          }
        }

        p {
          position: relative;
          top: -3px;
          left: 3px;
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;

          span,
          a {
            color: #2a9cb3;
          }
        }
      }
    }

    // Register Box Step Arrows

    .Arrow-box {
      width: 36px;
      height: 36px;
      border: 1px solid #233b53;
      border-radius: 100%;
      background: url(../../images/icons/arrow.svg) center center no-repeat;
      position: absolute;
      top: 117px;
      cursor: pointer;

      &.back {
        transform: rotate(180deg);
      }

      &.foward {
        right: 0;
      }

      &.block {
        opacity: 0.2;
        cursor: initial;
      }
    }
  }
}

@primary-color: #1DA57A;