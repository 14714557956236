.ConsentFormModal {
    h3 {
        font-weight: bold;
        font-size: 18px;
        color: #233b53;
    }

    .ant-modal-content {
        width: 620px;
        height: 370px;
    }

    .ant-checkbox-disabled+span {
        cursor: initial;
    }

    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
        cursor: initial;
    }

    .ant-checkbox-disabled .ant-checkbox-input {
        cursor: initial;
    }

    .ant-checkbox-wrapper p {
        margin-top: 2px;

        span {
            color: #233B53;
        }
    }

    .ant-checkbox-disabled {
        cursor: initial;
        filter: opacity(0.4);
    }

    .ant-row {
        min-height: initial;
    }

    .ant-radio {
        top: 3px;

        .ant-radio-inner {
            width: 17px;
            height: 17px;
            border-color: #233b53;

            &:after {
                top: 4px;
                left: 4px;
                width: 7px;
                height: 7px;
                background-color: #233b53;
            }
        }
    }
}

.WienerConsentFormModal {
    .ant-modal-content {
        width: 570px;
        height: 370px;
        font-size: 24px;
    }

    .ant-radio-wrapper span {
        font-size: 14px;
        color: #233B53;
        font-weight: bold;
    }

    .ant-radio-input {
        color: #233B53;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #233B53 !important;
    }

    .ant-radio-checked .ant-radio-inner:after {
        background-color: #233B53;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: #233B53;
    }

    a {
        color: #2A9CB3;
    }
}
@primary-color: #1DA57A;