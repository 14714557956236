.Tools {
  height: 50px;
  background-color: #e6e9ec;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
}

.Tools img {
  height: 100%;
  padding: 4px;
  background-color: white;
}

.Tools .content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Tools h1 {
  margin: 0;
}

@primary-color: #1DA57A;