

  .wlx-import-status {
      padding: 0px 5px 0px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 135px;
  }

  .wlx-status-symbol {
      color: #00A74F;
      margin-right: 0px;
      padding-left: 5px;
      ;
  }

  .wlx-status-dropdown-row-symbol-checked {
      visibility: visible;
  }

  .wlx-status-dropdown-row-symbol-unchecked {
      visibility: hidden;
  }

  .wlx-status-error {
      color: #233B53;
  }

  .wlx-status-right {
      color: #00A74F;
  }

  .wlx-status-wrong {
      color: #FF0000;
  }

  .wlx-status-selected {
      background-color: #F4F4F4 !important;
  }


  .wlx-status-button:hover {
      border: 1px solid #DDDDDD;
      color: #233B53;
  }

  .wlx-status-button:focus {
      border: 1px solid;
      color: #233B53;
      border-color: #DDDDDD;
  }

  .ReactTable {
      .rt-tr {
          .rt-th {
              div {
                  &::after {
                      top: 5px;
                  }
              }
          }
      }
  }

  #page-size {
      background: none;
  }

  #wl-status-dropdown {
      color: #233B53;
  }


  .templateModal {
      h3 {
          font-size: 18px;
          font-weight: bold;
      }

      p {
          text-align: center;
          color: #233b53;
      }

      button {
          width: 139px;
          height: 36px;

          span {
              text-transform: inherit;
          }
      }

      .ant-modal-body {
          padding-top: 44px;
          padding-left: 72px;
          padding-right: 72px;
      }

      .ant-modal-content {
          width: 495px;
          height: 299px;
          font-size: 24px;
      }

      .ant-radio-wrapper span {
          font-size: 14px;
          color: #233B53;
          font-weight: bold;
      }

      .ant-radio-input {
          color: #233B53;
      }

      .ant-radio-checked .ant-radio-inner {
          border-color: #233B53 !important;
      }

      .ant-radio-checked .ant-radio-inner:after {
          background-color: #233B53;
      }

      .ant-radio:hover .ant-radio-inner {
          border-color: #233B53;
      }

      .templateTypeName {
          margin-left: 20px
      }
  }


  .wlx-imports-dropdown-content {

      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #DDDDDD;
      opacity: 1;
      width: 295px;
      max-width: 400px;
      min-height: 215px;

      .broken-search {
          height: 38px;
          margin: 10px 15px 10px 15px;
      }

      .wlx-imports-dropdown-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 5px;
      }

      .wlx-imports-dropdown-row-checked {
          background-color: #F4F4F4 !important;
      }

      .ant-btn.ant-dropdown-trigger.wlx-status-button.wlx-imports-dropdown {
          font-weight: bold;
      }

      .wlx-imports-dropdown-inner-span {
          margin-left: 15px;
      }

      .wlx-imports-dropdown-row-symbol {
          color: green;
          margin-left: 5px;
      }

      .wlx-imports-dropdown-row-symbol-checked {
          visibility: visible;

      }

      .wlx-imports-dropdown-row-symbol-unchecked {
          visibility: hidden;
      }

      .ant-menu-item-selected {
          color: #233B53;
      }

      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
      .ant-menu-submenu-active,
      .ant-menu-submenu-title:hover {
          color: #233B53;
      }
  }

  .wlx-imports-dropdown-inner-selected-list {
      max-height: 150px;
      overflow: auto;
      overflow-x: unset;
  }

  .noselect {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }




.jobs-filtet-search {
    height: 45px;
    margin: 10px 15px 10px 15px;
  }
@primary-color: #1DA57A;