.JobsHistory {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 0px 12px #e8e8e8;
  min-height: 100%;
  height: 100%;

  .jobActionButton {
    border: 0px;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .noResultJob {
    padding-left: 15px;
  }


  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-right: 35px;
    padding-left: 35px;

    span {
      color: #233b53;
      font-size: 18px;
      text-transform: uppercase;
      font: normal normal bold 18px/24px Lato;
    }

    button {
      font-size: 14px;

      span {
        color: #233b53;
        font: normal normal bold 14px/24px Lato;
        text-transform: unset;
      }
    }

    .clear-filters {
      text-decoration: underline;
      font: normal normal bold 12px/16px Lato;
      text-transform: lowercase;
      margin-right: 30px;
      cursor: pointer;
    }

    .actions {
      // height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;

      .icon-box {
        margin-left: 20px;
        min-width: 23px;

        img {
          min-width: 21px;
        }
      }

      .action-icon {
        margin-left: 20px;
      }
    }
  }

  .ant-btn.ant-dropdown-trigger.filter-button.filter-dropdown {
    font-weight: bold;
  }

  .jobActionButton {
    border: 0px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.jobsTable {
  max-height: 100%;
  padding-bottom: 60px;

  .rt-tr {
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 42px;
  }

  .rt-th {
    word-break: break-all;
    white-space: normal;
  }

  .rt-tbody {
    overflow: hidden;
  }

  .rt-tbody .rt-tr-group {
    background-color: white;
    max-height: 42px;
  }

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    padding: 4px 5px !important;
  }

  .rt-thead {
    padding: 30px 40px 0px 35px;
    .rt-tr {
      .rt-th {
        display: flex;
        align-items: flex-end;

        font-size: 12px;
        height: 40px;
        // padding: 0;
        padding: 7px 5px;
        &:first-of-type {
          display: none;
        }
      }
    }
  }

  .rt-tbody {
    overflow: visible;
    padding: 0 40px 0 35px;
    .rt-tr {
      .rt-td {
        border: none;
        // padding: 0;
        font-size: 12px;
        text-overflow: ellipsis;
        display: block;
        align-items: center;

        &:first-of-type {
          position: absolute;
          left: -20px;
        }
        &:nth-of-type(2) {
          padding: 11px 8px;
          cursor: pointer;
        }
      }

      &.blocked {
        .rt-td {
          color: #bebebe !important;
        }
      }
    }
  }
  .rt-tr-group {
    border-bottom: 1px solid #dddddd !important;

    &:first-of-type {
      .vatRow {
        &::after {
          content: none !important;
        }
      }
    }
  }
  &.vatDetailsTable {
    .rt-thead {
      .rt-tr {
        .rt-th {
          align-items: center;
        }
      }
    }
    padding-top: 30px;
    &.detailVisible {
      max-width: calc(60% - 70px);

      .rt-thead,
      .rt-tbody {
        padding-right: 0;
      }
    }
    .rt-thead {
      padding-top: 0;
    }
    .rt-tbody {
      .rt-tr {
        height: 41px;
      }

      .rt-td {
        &:first-of-type {
          display: none;
        }
      }
      .rt-tr-group {
        cursor: pointer;
      }
    }
  }
  .ant-select-selector .ant-select-selection-item {
    font-size: 12px;
    max-height: 80%;
    margin: 0;
    margin-right: 5px;
  }
}
@primary-color: #1DA57A;